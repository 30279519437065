import React from "react";
import { Modal } from "react-bootstrap";
import thundaricon from "../../assets/img/products/thunder-icon.svg"
import upgradeplanimage from "../../assets/img/upgrade-plan-image.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";


export default function Teammoadal({from,showModal,setShowModal,setActivePage, heading, text, isUltra}) {
    const history = useHistory();
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const upgradeHandleClick = () => {
        if (!from || (from && !from?.includes("settings"))) {
           return history.push(basePath + "/settings");
        }else{
            setShowModal(false)
            return setActivePage("upgrade");
        }
        
    };
    const hideModal = () => {
        setShowModal(false)
        history.push(basePath + "/page-editor/customizePageModal/"+from)
    };
    return (
        <div class="upgradeTeamModal">
            <div id="upgradeTeamModal">
                <div className="" role="document">  
                    <div className="modal-content modal-semi-curve h-auto" style={{ boxShadow: "0px 0px 29px rgba(48, 49, 62, 0.12)", border: 'none' }}>

                        <div className="modal-body modal-u">
                            <div className="upgradeimg">
                                <img src={upgradeplanimage}/>
                            </div>
                            <form name="create_folder" className="create_folder" method="post" role="form" >
                                <div className="notification-container"></div>


                                <div className="form-group">
                                <span className={`project-types ${isUltra ? "ultra" : 'pro'}`}>
                                    {isUltra ? 'Ultra' : 'Pro'}
                                </span>
                                    <div className="mt-3">
                                        <h4 style={{fontSize:'22px'}}>Upgrade to Unlock. </h4>
                                        <p className="text-gray font-weight-500" style={{fontSize:"14px"}}>
                                        {text.split('<br />').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                {index !== text.split('<br />').length - 1 && <br />}
                                            </React.Fragment>
                                        ))}
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button
                                        type="button"
                                        onClick={upgradeHandleClick}
                                        style={{fontSize:"12px"}}
                                        className="btn mt-3 mb-2 px-3 btn-primary btn-semi-rounded"
                                    >
                                        Upgrade
                                    </button>
                                    {from && from.includes('customize') &&
                                        <button style={{fontSize:"12px"}} className="btn mt-3 mb-2 px-3 ms-2 btn-grey btn-semi-rounded" onClick={hideModal}>
                                            Close
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}