import { useReducer } from "react";
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { AuthProvider, authReducer } from './contexts/authContext';
import Routes from './routes';
import store from "../src/redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";


function App() {
  return (

    <Provider store={store}>
      <Router>
        <Switch>
            <Routes />
        </Switch>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Router>
    </Provider>
  );
}

export default App;
