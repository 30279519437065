import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";
const PaypalConnect = () => {
    const history = useHistory()

    const sendRequest = () => {
        const params = new URLSearchParams(location.search);

        localStorage.setItem("sendRequest", true)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        formdata.append("project", localStorage?.getItem("slug"))
        formdata.append("merchantId", params.get("merchantId"))
        formdata.append("merchantIdInPayPal", params.get("merchantIdInPayPal"))
        const api = apiUrl + "/paypal-connect/callback";
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        fetch(api, setOptions).then(async (res) => {
            localStorage.removeItem("sendRequest")
            const response = await res.json()
            if (response?.status_code == 200) toast.success(response?.status_message)
            else toast.error(response?.status_message);
            setTimeout(() => {
                
                history.push(localStorage.last_activity)
            }, 1000);
            return;
        }).catch((error) => {
            toast.error(error);
            localStorage.removeItem("sendRequest");
            setTimeout(() => {
                
                // history.back()
                history.push(localStorage.last_activity)
            }, 1000);
            return;
        })
    }

    useEffect(() => {
        if (!localStorage.sendRequest) sendRequest()
        else return true
    }, [])

    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default PaypalConnect