import React, { useEffect, useState } from 'react'
import { newDateFormat, newTimeFormat } from '../utils/setDateFormat'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Teammoadal from '../components/teammember/team_modal';
import PageHeader from '../components/page-header';
import NewSideBar from '../components/side-bar/new-sidebar';
import HeaderSearch from '../components/community/headerSearch';
import CommunityFooter from '../components/community/footer';
import chevron_downIcon from "../assets/img/modals/chevron-down.svg";
import tickIcon from "../assets/img/tick.svg";
import chevron_down_greyIcon from "../assets/img/chevron-down-gray.svg";
import getCodes from '../services/getCodes';
import tickCircleIcon from "../assets/img/tick-circle.svg"
import dotsIcon from "../assets/img/dots.svg"
import { ChevronRight } from '../utils/svg';
import { toast } from 'react-toastify';
import CodesModal from '../components/codes/codes-modal';
import { audiencePosts } from "../services/postRequests/audience";
import ConfirmationModal from '../components/confirmation-modal';
import { apiUrl } from '../utils/url';
import { hideLoader } from '../components/loader';
import EmptyState from './empty-state';



let params = [];
function DiscountCode({ basePath, id }) {
    const [showModalasd, setShowModalasd] = useState(false)
    const [showCode, setShowCode] = useState(false);
    let location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();
    const [loading, setloading] = useState(true);
    const { slug } = useSelector(state => state?.slugReducer);
    const isPro = useSelector(state => state?.proReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const [filter, setfilter] = useState({ page: 1, orderBy: null, filter: null, orderType: "asc", count: localStorage?.codes_count ?? 10});
    const [allCodes, setallCodes] = useState([]);
    const isEligiblie = useSelector(state => state?.eligibilityReducer);
    const [isEdit, setIsEdit] = useState({ is: false, id: null })
    const [selectAll, setSelectAll] = useState(false)
    const [isDuplicate, setIsDuplicate] = useState({ is: false, id: null })
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null, deleteAll: false });
    const [selectedRows, setSelectedRows] = useState([]); 
    const codeModalReducer = useSelector(state => state.codeModalReducer);
    const [allProducts, setAllProducts] = useState([])

    const showModal = () => {
        if (!isPro || !isEligiblie) {
            setShowModalasd(true);
        } else {
            dispatch({
                type: "RESET_CODE_MODAL"
            })
            setShowModalasd(false);
            setShowCode(true)
            history.push(basePath + "/codes/addCode")
        }
    }
    useEffect(() => {
        if (!isPro || !isEligiblie) {
            setShowModalasd(true);
        } else {
            setShowModalasd(false);
            params = [];
            if (slug) {
                for (const key in filter) {
                    if (Object.hasOwnProperty.call(filter, key)) {
                        if (filter[key])
                            params.push(`${[key]}=${filter[key]}`)
                    }
                };
                if (filter?.page){
                     callAllCodes()
                }
                    getProducts()
            }
        }
    }, [isPro, planType,isEligiblie]);
    const getProducts = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
          };
        const api = apiUrl + "/codes/get-products/" + slug;
          fetch(api, setOptions)
            .then(async (res) => {
              const response = await res?.json();
              if (response?.status_code == 200) {
                setAllProducts(response?.data);
              } else {
              }
            })
            .catch(() => {
              hideLoader();
            });
    };
    const selectFilter = (e) => {
        const value = e.target.dataset?.value !== "All" ? e.target.dataset?.value?.toLowerCase() : null;
        setfilter({ ...filter, filter: value, page: 1 });

    }
    const callAllCodes = () => {
        getCodes(slug, params?.join("&")).then((response) => {
            setloading(false)
            setallCodes(response);
        })
    }
    const copyingText = (text) => navigator.clipboard.writeText(text).then((r => toast.success("Successfully Copied")))
    useEffect(() => {
        if (location.pathname.includes("/codes/addCode")) {
            setShowCode(true)

        }
        else if (location.pathname.includes("/codes")) {
            setShowCode(false)
        }
    }, [location.pathname])
    const deleteAllCodes = (ids) => {
        // showLoader();
        const api = apiUrl + "/codes/delete-all";
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        var formData = new FormData();
        formData.append('slug',slug);
        const inputs = document.querySelectorAll('input[name="select-one"]:checked');
        inputs.forEach(input => {
            formData.append("id[]", input.dataset.id);
        });
        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        }
        audiencePosts(api, hideLoader, setOptions).then(() => {
            setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
            inputs.forEach(input => {
                input.checked = false;
            });
            const selectAllCheckbox = document.querySelector('input[name="select-all"]');
            if (selectAllCheckbox) {
                selectAllCheckbox.checked = false;
            }
            setSelectAll(false)
            setSelectedRows([]);
            callAllCodes()
        }).catch(() => {
                setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
                hideLoader()
            })
    }
    const handleCheckboxChange = (e, id) => {
        if (id === 'select-all') {
            const inputs = document.querySelectorAll('input[name="select-one"]');
            inputs.forEach(input => {
                input.checked = e.target.checked;
                if (e.target.checked) {
                    setSelectedRows(selectedRows => [...selectedRows, input?.dataset?.id]);
                } else {
                    setSelectedRows([]);
                }
            });
        }  else {
            if (e.target.checked) {
                setSelectedRows([...selectedRows, id]);
            } else {
                const selectAllCheckbox = document.querySelector('input[name="select-all"]');
                if (selectAllCheckbox) {
                    selectAllCheckbox.checked = false;
                }
                const updatedSelectedRows = selectedRows.filter(rowId => rowId !== id);
                setSelectedRows(updatedSelectedRows);
            }
        }    
    };
    const editCode = (values) => {
        dispatch({
            type: "SET_CODE_MODAL", payload: {
                ...codeModalReducer,
                id: values?.id,
                is_custom :values?.is_custom,
                code:values?.code,
                code_type :values?.code_type,
                amount :values?.amount,
                no_of_uses :values?.no_of_uses,
                no_of_used :values?.no_of_used,
                products:values?.products,
                cover_image:values?.cover_image,
                date_range:values?.date_range,
                date_range_end:values?.date_range_end,
            }
        })

        setIsEdit({ is: true, id: values?.id })
        sessionStorage.setItem("isEdit", JSON.stringify(isEdit))

        setShowCode(true)
    }
    const changeStatusCode = (val_id) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
            };
        const api = apiUrl + "/codes/change-status/" + val_id;
        audiencePosts(api, hideLoader, setOptions).then(() => {
            const updatedCodes = allCodes.data.map(code => {
                if (code.id === val_id) {
                    return { ...code, status: !code.status };
                }
                return code;
            });
            setallCodes({ ...allCodes, data: updatedCodes });
        }).catch((err) => {
            setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
        })
    }
    const confirmedDeleteBlock = (e) => {
        if (confirmationModalShow?.id) {
            const api = apiUrl + "/codes/delete/" + confirmationModalShow?.id;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const setOptions = {
                method: "DELETE",
                headers: myHeaders,
            }
            audiencePosts(api, hideLoader, setOptions).then(() => {
                setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
                callAllCodes();
            }).catch((err) => {
                setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
            })
        }

    }
    return (
        <div id="wrapper">
            <PageHeader />
            <div class="community-main new-sidebar">
                <NewSideBar />

                <section class="community-section">
                    {showModalasd && <Teammoadal showModal={showModalasd} heading={'Upgrade to access Codes.'} text={' This is a Ultra Feature<br /> Upgrade to Zaap Ultra to unlock this feature.'} isUltra={true} />}

                    <div class="section-header sec-head-u">
                        <h1 class="mb-0">Discounts</h1>
                        <div class="d-flex full-flex">
                            <HeaderSearch filter={filter} setfilter={setfilter} name="discounts" />
                            <button class="btn btn-primary btn-semi-rounded import-btn align-items-center 
                            justify-content-center newbtn-u" id="btn-newCodeModal" onClick={showModal}>
                                New Discount
                            </button>
                        </div>
                    </div>
                    {!allCodes?.data?.length > 0 && !showModalasd && (!filter?.filter || filter?.filter == 'All') ?
                        <div class="section-main sec-main-u p-0 position-relative">
                            <EmptyState from={"discount_codes"} firstText={'Start Discounting'} secondText={"Offer discount codes to  boost <br/>sales"} buttonText={"Create Discount"} clickedFunction={showModal} />
                        </div>
                        :
                        <div class="section-main sec-main-u">
                            <div class="d-flex table-responsive" style={{ overflow: "visible" }}>
                                <table class="table" border="0">
                                    <thead class="d-md-table-header-group dashview-u">
                                        <tr>
                                            <th className={`aud-leftborder thead-padd first_td aud-text ${selectAll ? ' selected ' : ''} post-first-col-w`}>
                                                {allCodes?.data?.length > 0 &&
                                                    <label className="custom-checkbox checkbox">
                                                        <input type="checkbox" name="select-all" data-id="select-all" onChange={(e) => handleCheckboxChange(e, 'select-all')}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                }
                                                {selectedRows?.length > 0 ?
                                                    <button className="delete-all-btn" onClick={() => setconfirmationModalShow({show: true, text: "Are you sure you want to delete Codes?", id: selectedRows, callBackTo: "delete", deleteAll: true})}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                                            <path d="M8.77364 0.564453C9.54452 0.564453 10.1761 1.17108 10.2319 1.94053L10.2359 2.05151V3.53857H12.4293C12.8331 3.53857 13.1605 3.87146 13.1605 4.2821C13.1605 4.6634 12.8782 4.97767 12.5146 5.02062L12.4293 5.02562H12.3789L11.8418 12.6728C11.736 14.179 10.54 15.3569 9.07236 15.4313L8.92474 15.435H4.23572C2.75088 15.435 1.51038 14.3057 1.33282 12.8223L1.31862 12.6728L0.780849 5.02562H0.731137C0.327341 5.02562 0 4.69274 0 4.2821C0 3.90079 0.282235 3.58652 0.645858 3.54357L0.731137 3.53857H2.92455V2.05151C2.92455 1.26756 3.52104 0.625299 4.27767 0.568532L4.38682 0.564453H8.77364ZM10.9122 5.02562H2.24749L2.77719 12.5669C2.82639 13.2672 3.34705 13.8256 4.00996 13.9303L4.1217 13.9435L4.23572 13.948H8.92474C9.65355 13.948 10.2646 13.4038 10.3708 12.6822L10.3833 12.5669L10.9122 5.02562ZM5.11796 7.25621C5.49291 7.25621 5.80195 7.54324 5.84418 7.91303L5.84909 7.99974V10.9739C5.84909 11.3845 5.52175 11.7174 5.11796 11.7174C4.743 11.7174 4.43396 11.4304 4.39173 11.0606L4.38682 10.9739V7.99974C4.38682 7.5891 4.71416 7.25621 5.11796 7.25621ZM8.0425 7.25621C8.41745 7.25621 8.72649 7.54324 8.76873 7.91303L8.77364 7.99974V10.9739C8.77364 11.3845 8.4463 11.7174 8.0425 11.7174C7.66755 11.7174 7.35851 11.4304 7.31627 11.0606L7.31137 10.9739V7.99974C7.31137 7.5891 7.63871 7.25621 8.0425 7.25621ZM8.77364 2.05151H4.38682V3.53857H8.77364V2.05151Z" fill="#FF2020"/>
                                                        </svg>
                                                        <span>Delete </span>
                                                    </button>
                                                    :
                                                    'Code'
                                                }
                                            </th>
                                            <th className='middleWidth'></th>
                                            <th class={`thead-padd middleWidth d-none_520 resp ${selectedRows?.length ? " invisible" : ''}`} onClick={(e) => filterToggler(e, "date_joined")}>
                                                <span className="sort-u width-const2">
                                                    Uses &nbsp;&nbsp;
                                                    <img src={chevron_down_greyIcon} alt="" />
                                                </span>
                                            </th>
                                            <th class={`thead-padd resp d-none_520 ${selectedRows?.length ? " invisible" : ''}`} onClick={(e) => filterToggler(e, "date_joined")}>
                                                <span className="sort-u width-const">
                                                    Amount&nbsp;&nbsp;
                                                    <img src={chevron_down_greyIcon} alt="" />
                                                </span>
                                            </th>
                                            <th className={`th-end-u ${selectedRows?.length ? " invisible" : ''}`}></th>
                                        </tr>
                                    </thead>
                                    {
                                        loading ?
                                            <tbody>

                                                <tr>
                                                    <td className="d-md-table-cell">


                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                        <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                            <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                                <Skeleton height={40}
                                                                    className="d-md-none"
                                                                    borderRadius="10px"
                                                                    highlightColor="#ffffff"
                                                                    duration={4}
                                                                />

                                                                <span class="d-md-none d-flex text-lightgray">
                                                                    <span class="me-4">21 Sep</span>
                                                                    <span class="me-4">1.2k Sends</span>
                                                                    <span>556 Opens</span>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="d-md-table-cell d-none">

                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td className="d-md-table-cell">


                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                        <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                            <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                                <Skeleton height={40}
                                                                    className="d-md-none"
                                                                    borderRadius="10px"
                                                                    highlightColor="#ffffff"
                                                                    duration={4}
                                                                />

                                                                <span class="d-md-none d-flex text-lightgray">
                                                                    <span class="me-4">21 Sep</span>
                                                                    <span class="me-4">1.2k Sends</span>
                                                                    <span>556 Opens</span>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="d-md-table-cell d-none">

                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td className="d-md-table-cell">


                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                        <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                            <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                                <Skeleton height={40}
                                                                    className="d-md-none"
                                                                    borderRadius="10px"
                                                                    highlightColor="#ffffff"
                                                                    duration={4}
                                                                />

                                                                <span class="d-md-none d-flex text-lightgray">
                                                                    <span class="me-4">21 Sep</span>
                                                                    <span class="me-4">1.2k Sends</span>
                                                                    <span>556 Opens</span>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="d-md-table-cell d-none">

                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="d-md-table-cell">


                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                        <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                            <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                                <Skeleton height={40}
                                                                    className="d-md-none"
                                                                    borderRadius="10px"
                                                                    highlightColor="#ffffff"
                                                                    duration={4}
                                                                />

                                                                <span class="d-md-none d-flex text-lightgray">
                                                                    <span class="me-4">21 Sep</span>
                                                                    <span class="me-4">1.2k Sends</span>
                                                                    <span>556 Opens</span>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="d-md-table-cell d-none">

                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td className="d-md-table-cell">


                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                        <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                            <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                                <Skeleton height={40}
                                                                    className="d-md-none"
                                                                    borderRadius="10px"
                                                                    highlightColor="#ffffff"
                                                                    duration={4}
                                                                />

                                                                <span class="d-md-none d-flex text-lightgray">
                                                                    <span class="me-4">21 Sep</span>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="d-md-table-cell d-none">

                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">

                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="d-md-table-cell">


                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                        <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                            <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                                <Skeleton height={40}
                                                                    className="d-md-none"
                                                                    borderRadius="10px"
                                                                    highlightColor="#ffffff"
                                                                    duration={4}
                                                                />

                                                                <span class="d-md-none d-flex text-lightgray">
                                                                    <span class="me-4">21 Sep</span>
                                                                    <span class="me-4">1.2k Sends</span>
                                                                    <span>556 Opens</span>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="d-md-table-cell d-none">

                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>


                                                </tr>

                                                <tr>
                                                    <td className="d-md-table-cell">


                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                        <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                            <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                                <Skeleton height={40}
                                                                    className="d-md-none"
                                                                    borderRadius="10px"
                                                                    highlightColor="#ffffff"
                                                                    duration={4}
                                                                />

                                                                <span class="d-md-none d-flex text-lightgray">
                                                                    <span class="me-4">21 Sep</span>
                                                                    <span class="me-4">1.2k Sends</span>
                                                                    <span>556 Opens</span>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="d-md-table-cell d-none">

                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td class="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                </tr>

                                            </tbody>
                                            :
                                            <tbody>
                                                {
                                                    allCodes?.data?.length > 0 && (
                                                        allCodes?.data?.map((val, index) => (
                                                            <tr key={index} >
                                                                <td >
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className="custom-checkbox checkbox">
                                                                        <input type="checkbox" name="select-one" data-id={val.id} onChange={(e) => handleCheckboxChange(e, val.id)}/>
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                        <div className='code-btn-u'>
                                                                            <span>{val?.code}</span>
                                                                            <span onClick={()=>copyingText(val?.code)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">  <path d="M10.5636 10.5636C10.711 10.5418 10.8421 10.5122 10.9657 10.4721C11.917 10.1629 12.6629 9.41705 12.9721 8.46568C13.125 7.99495 13.125 7.4133 13.125 6.25C13.125 5.0867 13.125 4.50505 12.9721 4.03432C12.6629 3.08295 11.917 2.33707 10.9657 2.02795C10.495 1.875 9.9133 1.875 8.75 1.875C7.5867 1.875 7.00505 1.875 6.53432 2.02795C5.58295 2.33707 4.83707 3.08295 4.52795 4.03432C4.48781 4.15786 4.4582 4.28904 4.43637 4.43637M10.5636 10.5636C10.625 10.1496 10.625 9.608 10.625 8.75C10.625 7.5867 10.625 7.00505 10.4721 6.53432C10.1629 5.58295 9.41705 4.83707 8.46568 4.52795C7.99495 4.375 7.4133 4.375 6.25 4.375C5.392 4.375 4.85041 4.375 4.43637 4.43637M10.5636 10.5636C10.5418 10.711 10.5122 10.8421 10.4721 10.9657C10.1629 11.917 9.41705 12.6629 8.46568 12.9721C7.99495 13.125 7.4133 13.125 6.25 13.125C5.0867 13.125 4.50505 13.125 4.03432 12.9721C3.08295 12.6629 2.33707 11.917 2.02795 10.9657C1.875 10.495 1.875 9.9133 1.875 8.75C1.875 7.5867 1.875 7.00505 2.02795 6.53432C2.33707 5.58295 3.08295 4.83707 4.03432 4.52795C4.15786 4.48781 4.28904 4.4582 4.43637 4.43637" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="d-md-table-cell ">
                                                                    <span class="d-flex align-items-center">
                                                                        <div className='statusbox-u status-badge'>
                                                                            <span className='small-box' style={{backgroundColor:val?.status ? "#0BE714" : "#FF1451"}}></span>
                                                                            <span>{val?.status ? 'Active' : 'Disabled'}</span>
                                                                        </div>
                                                                    </span>
                                                                </td>
                                                                <td class="p-0 d-none_520">
                                                                    <span className='cod-boxes width-const2'>{val?.no_of_uses}</span>
                                                                </td>
                                                                <td class="p-0 d-none_520">
                                                                   <span className='cod-boxes width-const'>{val?.amount } { val?.code_type == 'fixed' ? '$' : '%'}</span> 
                                                                </td>
                                                                <td>
                                                                    <div class="table-action-dropdown float-end dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                        <img src={dotsIcon} width="" alt="" />
                                                                    </div>
                                                                    <ul class="dropdown-menu" aria-labelledby="table-action-dropdown">
                                                                        <li><a class="dropdown-item" onClick={() => copyingText(val?.code)} ><span class="me-5">Copy Code</span><ChevronRight /></a></li>
                                                                        <li><a class="dropdown-item"  onClick={() => changeStatusCode(val?.id)}><span class="me-5">{val.status ? 'Disable ' : 'Enable '} Discount</span><ChevronRight /></a></li>
                                                                        <li><a class="dropdown-item" onClick={() => editCode(val)}><span class="me-5">Edit Discount</span><ChevronRight /></a></li>
                                                                        <li><a class="dropdown-item theme-active semi-bold" onClick={() =>
                                                                            setconfirmationModalShow({
                                                                                show: true, text: "Are you sure you want to delete this code?", id: val?.id, callBackTo: "delete"
                                                                            })} ><span class="me-5">Delete Discount</span><ChevronRight /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                            </tbody>
                                    }
                                </table>
                            </div>


                            <div class="section-footer d-flex align-items-center justify-content-between">
                                <CommunityFooter from={"codes"} loading={loading} filter={filter} setfilter={setfilter} total={allCodes?.total} last_page={allCodes?.last_page} current_page={allCodes?.current_page} />
                            </div>

                        </div>
                    }
                </section>

            </div>
            {showCode && <Route path={basePath + "/codes/:addCode"} children={() => <CodesModal from={"codes"} basePath={basePath} show={showCode}
                allProducts={allProducts} setAllProducts={setAllProducts} setShow={setShowCode} id={id} callAllCodes={callAllCodes} edit={isEdit} setIsEdit={setIsEdit} duplicate={isDuplicate} setIsDuplicate={setIsDuplicate} />}
            />}
            {confirmationModalShow?.show &&
                <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={confirmationModalShow?.deleteAll ? deleteAllCodes : confirmedDeleteBlock} />
            }
        </div>
    )
}

export default DiscountCode