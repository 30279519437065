import PageHeader from "../components/page-header";
import searchIcon from "../assets/img/search-icon.svg";
import chevron_down_greyIcon from "../assets/img/chevron-down-gray.svg";
import exportIcon from "../assets/img/export-icon.svg";
import defaultIcon from "../assets/img/products/default.png";
import "../assets/css/monetization.css";
import * as $ from "jquery";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import SideBar from "../components/side-bar";
import { useSelector } from "react-redux";
import { hideLoader, showLoader } from "../components/loader";
import getNewsletter from "../services/getNewsletter";
import HeaderSearch from "../components/community/headerSearch";
import CommunityFooter from "../components/community/footer";
import setDateFormat from "../utils/setDateFormat";
import PayoutSettings from "../components/payout-settings";
import { apiUrl } from "../utils/url";
import { audiencePosts } from "../services/postRequests/audience";
import defaultProductImage from "../assets/img/products/default-Product-image"
import NewSideBar from "../components/side-bar/new-sidebar";
import Teammoadal from "../components/teammember/team_modal";
import EmptyState from "./empty-state";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ChevronRight } from "../utils/svg";
import ConfirmationModal from "../components/confirmation-modal";
import dotsIcon from "../assets/img/dots.svg"
import { toast } from "react-toastify";

let params = [];
const Orders = () => {
  const [loading, setloading] = useState(true);
  const monetizationRef = useRef("active");
  const monetizationMobileRef = useRef("active");
  const { slug,basePath } = useSelector((state) => state?.slugReducer);
  const { stripeConnectReducer } = useSelector((state) => state);
  const [payoutSettingsModal, setpayoutSettingsModal] = useState(false);
  const [allOrders, setallOrders] = useState([]);
  const pro = useSelector(state => state?.proReducer);
  const newUser = useSelector(state => state?.newUserReducer);
  const planType = useSelector(state => state?.plantypeReducer);
  const [showModalasd, setShowModalasd] = useState(false)
  const history = useHistory();
  const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null });
  const [filter, setfilter] = useState({
    page: 1,
    orderBy: "id",
    filter: null,
    orderType: "desc",
    count: localStorage?.orders_count ?? 10
  });

  useEffect(() => {
  if (!pro) {
    setShowModalasd(true);
  }else {
    setShowModalasd(false);
    params = [];
    if (slug) {
      for (const key in filter) {
        if (Object.hasOwnProperty.call(filter, key)) {
          if (filter[key]) params.push(`${[key]}=${filter[key]}`);
        }
      }
      if (filter?.page) callOrders();
    }
  }
  }, [slug, filter,pro, planType]);

  const callOrders = () => {
      //   let getRows = 12;
      //   if (window.innerHeight < 1000) {
      //     getRows = 12;
      // }else if (window.innerHeight >= 1100 && window.innerHeight <= 1200) {
      //     getRows = 13;
      // }else if (window.innerHeight >= 1200 && window.innerHeight <= 1300) {
      //     getRows = 14;
      // }else if (window.innerHeight >= 1300 && window.innerHeight <= 1400) {
      //     getRows = 15;
      // }else if (window.innerHeight >= 1400 && window.innerHeight <= 1500) {
      //     getRows = 22;
      // }else if (window.innerHeight >= 1500 && window.innerHeight <= 1600) {
      //     getRows = 24;
      // }else{
      //     getRows = 27;
      // }
      //   params.push(`pagination=${[getRows]}`)
    getNewsletter(slug, params?.join("&"), "monetization/orders")
      .then((response) => {
        setloading(false);
        setallOrders(response);
      })
  };

  const filterToggler = (e, value) => {
    setfilter({
      ...filter,
      orderBy: !$(e.target).hasClass("sortable-col-desc") ? value : value,
      orderType: !$(e.target).hasClass("sortable-col-desc") ? "desc" : "asc",
    });
    if ($(e.target).hasClass("sortable-col-desc")) {
      $(".sortable-col").removeClass("sortable-col-desc");
    } else {
      $(".sortable-col").removeClass("sortable-col-desc");
      $(e.target).addClass("sortable-col-desc");
    }
  };
  useEffect(() => {
    $(monetizationRef.current).addClass("active");
    $(monetizationMobileRef.current).addClass("active");
  }, []);

  const payoutConnect = (e) => upgradeSubmit(e, null, slug, "connect");

  const exportCSV = () => {
    showLoader();
    const api = apiUrl + "/monetization/orders/export";
    const formdata = new FormData();
    formdata.append("project", slug)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    const setOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata
    }
    audiencePosts(api, hideLoader, setOptions, "export")
}
  const sellingClick = () => {
    history.push(basePath + "/products")
  }
  const refundOrder = (order_id) => {
    showLoader();
    const api = apiUrl + "/monetization/orders/refund-order";
    const formdata = new FormData();
    formdata.append("project", slug)
    formdata.append("order_id", order_id)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    const setOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata
    }
    audiencePosts(api, hideLoader, setOptions)
    setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
  };
  const resendReceipt = (order_id) => {
      showLoader();
      const api = apiUrl + "/monetization/orders/resend-receipt";
      const formdata = new FormData();
      formdata.append("project", slug)
      formdata.append("order_id", order_id)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
      const setOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata
      }
      audiencePosts(api, hideLoader, setOptions)
      setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
  };
  const copyCustomerEmail = (text) => navigator.clipboard.writeText(text).then((r => toast.success("Successfully Copied")))
  return (
    <>
      <div id="wrapper">
        <PageHeader
          monetizationRef={monetizationRef}
          monetizationMobileRef={monetizationMobileRef}
        />
        <div class="monetization-main">
          {/* <SideBar
            from="orders"
            setpayoutSettingsModal={setpayoutSettingsModal}
          /> */}
          <NewSideBar/>

          <section class="monetization-section">
          
          {showModalasd && <Teammoadal showModal={showModalasd} heading={'Upgrade to access Orders.'} text={' Orders is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false}/>}
            <div class="section-header sec-head-u">
              <h1 class="mb-0">Orders</h1>
              <div class="d-flex full-flex">
              <HeaderSearch
                filter={filter}
                setfilter={setfilter}
                name="orders"
              />
                <button
                  class="btn btn-primary btn-semi-rounded import-btn align-items-center 
                  justify-content-center newbtn-u"
                  data-bs-toggle="modal"
                  data-bs-target="#newProductModal"
                  onClick={exportCSV}
                  disabled={allOrders?.data?.length > 0 ? false : true}
                >
                  Export
                </button>
              </div>
            </div>
            {!allOrders?.data?.length > 0 && !showModalasd?
            <div class="section-main sec-main-u position-relative">
              <EmptyState from={"orders"} firstText={'Don’t quit your day job.'} secondText={"You don’t have any orders just yet. Start promoting and watch the orders roll in!"} buttonText={"Start Selling"} clickedFunction={sellingClick}/>
            </div>
            :
            <div class="section-main sec-main-u">
              <div class="d-flex table-responsive ">
                <table class="table" border="0">
                  <thead class="d-md-table-header-group  dashview-u">
                    <tr>
                      <th
                        class="sortable-col"
                        onClick={(e) => filterToggler(e, "id")}
                      >
                        #&nbsp;&nbsp;
                        <img src={chevron_down_greyIcon} alt="" />
                      </th>
                      <th>Product</th>
                      <th>Customer</th>
                      <th class=" thead-padd resp d-none-u" onClick={(e) => filterToggler(e, "date")}>
                          <span className="sort-u">
                              Purchased&nbsp;&nbsp;<img src={chevron_down_greyIcon} alt="" />
                          </span>
                      </th>
                      {/* <th>Customer Email</th> */}
                      <th class=" thead-padd resp d-none-u" onClick={(e) => filterToggler(e, "revenue")}>
                          <span className="sort-u">
                              Revenue&nbsp;&nbsp;<img src={chevron_down_greyIcon} alt="" />
                          </span>
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                        <td class="d-md-table-cell d-none">
                          <Skeleton
                            height={40}
                            borderRadius="10px"
                            highlightColor="#ffffff"
                            duration={4}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {allOrders?.data?.length > 0 &&
                        allOrders?.data?.map((val, index) => (
                          <tr>
                            <td>
                              <p class="mb-0 d-inline-flex align-items-center justify-content-start">
                                <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                  <span class="d-flex align-items-center">
                                    <span>{val?.order_id}</span>
                                    {/* <span class="d-md-none d-flex ms-3">
                                      {val?.customer?.name}
                                    </span> */}
                                    {/* <span class="d-md-none d-flex ms-3">
                                      {val?.customer?.email}
                                    </span> */}
                                  </span>
                                  {/* <span class="d-md-none d-flex text-lightgray">
                                    <small class="me-4">
                                      {setDateFormat(val?.date, true)}
                                    </small>
                                    <small class="me-4">
                                      ${val?.revenue} Revenue
                                    </small>
                                    <small>{val?.product?.name}</small>
                                  </span> */}
                                </span>
                              </p>
                            </td>
                            {/* <td class="d-md-table-cell d-none">
                              <p class="mb-0 d-inline-flex align-items-center justify-content-start">
                                <span>{val?.customer?.name}</span>
                              </p>
                            </td> */}
                            <td class="">
                              <p class="mb-0 d-inline-flex align-items-center justify-content-start">
                                <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                  <span class="d-flex align-items-center">
                                    <img
                                      src={val?.product?.cover_images[0]?.images || val?.product?.cover_image || defaultProductImage}
                                      width="60px"
                                      class="d-md-flex d-none me-2 zaap-border-rounded border-radius-10"
                                      alt=""
                                    />
                                    <span>{val?.product?.name}</span>
                                  </span>
                                  <span class="d-md-none d-flex text-lightgray">
                                    {/* <span class="me-4">${val?.revenue}</span>
                                    <span class="me-4">{val?.sales} Sales</span>
                                    <span>${val?.revenue} Revenue</span> */}
                                  </span>
                                </span>
                              </p>
                            </td>
                            <td class="">
                              <p class="mb-0 d-inline-flex align-items-center justify-content-start">
                              <span class="subscriber-name-icon me-2">{val?.customer?.name.substring(0, 1).toUpperCase()}</span>
                                <span>{val?.customer?.email}</span>
                              </p>
                            </td>
                            <td class="d-md-table-cell d-none">
                              {setDateFormat(val?.date, true)}
                            </td>
                            <td class="d-md-table-cell d-none">
                            {val?.product?.currency == "eur" ? '€' : val?.product?.currency == "gbp" ? '£' : '$'}{val?.revenue}
                            </td>
                            <td>
                                <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end dropdown-subscribers" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                                    <img src={dotsIcon} width="" alt="" />
                                </div>
                                <ul class="dropdown-menu" aria-labelledby="table-action-dropdown">
                                    <li><a class="dropdown-item" onClick={() =>
                                    setconfirmationModalShow({show: true, text: "Are you sure you want to resend Receipt?", id: val?.order_id, callBackTo: "resend"})}><span class="me-5">Resend Receipt</span><ChevronRight /></a></li>
                                    <li><a class="dropdown-item" onClick={() => copyCustomerEmail(val?.customer?.email)} ><span class="me-5">Copy Customer</span><ChevronRight /></a></li>
                                    {val?.charge_id || val?.paypal_charge_id ? <li><a class="dropdown-item theme-active semi-bold" onClick={() =>
                                    setconfirmationModalShow({
                                        show: true, text: "Are you sure you want to refund?", id: val?.order_id, callBackTo: "refund"
                                    })} ><span class="me-5">Refund Order</span><ChevronRight /></a></li>: ''}
                                </ul>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>

              <div class="section-footer d-flex align-items-center justify-content-between">
                <CommunityFooter
                  from={"orders"}
                  loading={loading}
                  filter={filter}
                  setfilter={setfilter}
                  total={allOrders?.total}
                  last_page={allOrders?.last_page}
                  current_page={allOrders?.current_page}
                />
              </div>
            </div>
            }
          </section>
        </div>
      </div>
      <PayoutSettings
        show={payoutSettingsModal}
        setShow={setpayoutSettingsModal}
        connectStripe={payoutConnect}
        stripeConnectReducer={stripeConnectReducer}
      />
        {confirmationModalShow?.show &&
            <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={confirmationModalShow?.callBackTo == 'resend'? resendReceipt : refundOrder}/>
        }
    </>
  );
};
export default Orders;
