import { toast } from "react-toastify";
import { apiUrl } from "../../utils/url";
import { hideLoader, showLoader } from "../loader";
import { audiencePosts as upgradeProPost } from "../../services/postRequests/audience";

const upgradeSubmit = (e, code, slug, isConnect, end_point) => {
    e.preventDefault();
    showLoader()

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
    const formdata = new FormData()
    formdata.append("project", slug ? slug : localStorage?.getItem("slug"));
    if (code) {
        formdata.append("promo_code", code)
    }
    formdata.append("tolt_referral", window.tolt_referral??'');
    const api = apiUrl + `/${end_point}-${isConnect ? "connect" : "checkout"}/request`;
    const setOptions = {
        method: "POST",
        body: formdata,
        headers: myHeaders
    }
    localStorage.setItem("last_activity",location.pathname)
    upgradeProPost(api, hideLoader, setOptions).then((response) => {
        showLoader()
        localStorage.removeItem("sendRequest")
        if (response) {
            const popup_window =  location.href = response?.url
            // const popup_window = window.open(
            //     response?.url,
            //     'targetWindow',
            //     `toolbar=no,
            //     location=center,
            //     status=no,
            //     menubar=no,
            //     scrollbars=yes,
            //     resizable=yes,
            //     width=${screen.width},
            //     height=${screen.height}`)

            let isClosed = false;
            const checkChild = () => {
                
// console.log("isClosed", isClosed);
                if (isClosed) return
                if (popup_window.closed) {
                    isClosed = true
                    const stripe_connect_return = JSON.parse(localStorage.getItem("stripe_connect_return"))
                    if (stripe_connect_return?.stripe_error) {
                        toast.error(stripe_connect_return?.message);
                        localStorage.removeItem("stripe_connect_return")
                    } else if (stripe_connect_return?.isSuccess) {
                        toast.success(stripe_connect_return?.message)
                        localStorage.removeItem("stripe_connect_return")
                        setTimeout(() => {
                            
                            hideLoader()
                            history.go()
                        }, 200);
                        return
                    } else if (stripe_connect_return?.isSuccess==false) {
                        toast.error(stripe_connect_return?.message)
                        localStorage.removeItem("stripe_connect_return")
                        hideLoader()
                        return
                    }

                    if (isConnect && end_point=="stripe") toast.error(`Stripe Connection Unsuccessful`)
                    hideLoader()
                    return
                }
            }
            if (isClosed) return;
            else
                var timer = setInterval(checkChild, 3000);

        }
        else {
            hideLoader()
        }
    }).catch(() => hideLoader())


}
export default upgradeSubmit;

export const disconnectPayout = (e,slug,setdisconnectModal) => {
    e.preventDefault();
    showLoader()

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
    const formdata = new FormData()
    formdata.append("project", slug ? slug : localStorage?.getItem("slug"));
    
    const api = apiUrl + `/paypal-disconnect`;
    const setOptions = {
        method: "POST",
        body: formdata,
        headers: myHeaders
    }
    upgradeProPost(api, hideLoader, setOptions).then((response) => {
        showLoader()
        localStorage.removeItem("sendRequest")
        if (response) {
            setdisconnectModal(false);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
            // const popup_window = window.open(
            //     response?.url,
            //     'targetWindow',
            //     `toolbar=no,
            //     location=center,
            //     status=no,
            //     menubar=no,
            //     scrollbars=yes,
            //     resizable=yes,
            //     width=${screen.width},
            //     height=${screen.height}`)

        }
        else {
            hideLoader()
        }
    }).catch(() => hideLoader())


}