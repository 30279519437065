import { useHistory } from "react-router-dom"
import { hideLoader, showLoader } from "../../loader"
import { Modal } from "react-bootstrap"
import { mainPath } from "../../../pages/page-editor"
import { useDispatch, useSelector } from "react-redux";
import { audiencePosts as eventTracking } from "../../../services/postRequests/audience";
import { apiUrl } from "../../../utils/url";
import linkblock from "../../../assets/img/blocks/link-folder.svg"
import zaapPost from "../../../assets/img/blocks/zaap-post.svg"
import singlelink from "../../../assets/img/blocks/single-link.svg"
import textblock from "../../../assets/img/blocks/textblock.svg"
import embed from "../../../assets/img/blocks/embed.svg"
import contactBlock from "../../../assets/img/blocks/contact-pro-block.svg"
import youtubeBlock from "../../../assets/img/blocks/youtube-pro-block.svg"
import instagramBlock from "../../../assets/img/blocks/instagram-pro-block.svg"
import tiktokBlock from "../../../assets/img/blocks/tiktok-pro-block.svg"
import productBlock from "../../../assets/img/blocks/product-pro-block.svg"
import coachingBlock from "../../../assets/img/blocks/coaching-pro-block.svg"
import leadMagnetBlock from "../../../assets/img/blocks/lead-magnet.svg"
import emailBlock from "../../../assets/img/blocks/block3.svg"
import Teammoadal from "../../teammember/team_modal";
import { useState } from "react";
import CreateLinkModal from "./sub-modals/createLinkModal";
import CreateProductWidgetModal from "./sub-modals/createProductWidgetModal";
import CreateMeetingProductWidgetModal from "./sub-modals/createMeetingProductWidgetModal";

const BlockEditor = ({ handleClose, show, slug,blockModal = null ,handleSubmit = null,returnIcon =null,setBlockModal }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [showProModal, setShowProModal] = useState(false)
    const basePath = useSelector(state => state?.slugReducer?.basePath)
    const isPro = useSelector(state => state?.proReducer)
    const planType = useSelector(state => state?.plantypeReducer)
    const isNewUser = useSelector(state => state?.newUserReducer)
    // console.log("isNewUser",isNewUser,"isPro",isPro);
    // const show = (isNewUser && !isPro) || (isPro && !isNewUser); 
    const showModal = (paramsName) => {
        showLoader();
        if (!isPro && (paramsName.includes("Youtube") || paramsName.includes("Tiktok") || paramsName.includes("Twitter") || paramsName.includes("Contact") || paramsName.includes("Product") || paramsName.includes("Instagram") || paramsName.includes("ZaapPosts"))) {
            // handleClose()
            // setShowProModal(true);
            history.push(basePath + "/settings")
            hideLoader()
            return
        }
        // else if(isNewUser && planType != "ultra" && paramsName.includes("Product")){
        //     handleClose()
        //     history.push(basePath + "/settings")
        //     hideLoader()
        //     return
        // }
        // if ( !isPro && (paramsName.includes("Youtube") || paramsName.includes("Tiktok") || paramsName.includes("Twitter") || paramsName.includes("Contact"))) {
        //     handleClose()
        //     history.push(basePath + "/settings")
        //     // dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: true, open: true } })
        //     hideLoader()
        //     return
        // }
        history.push({
            pathname: `${basePath}/${mainPath}/${paramsName}`,
            state: { showChild: true }
        })
        hideLoader()
    }
    const eventTrackforContact = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("name", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        }).catch((err) => {
        // console.log(err))
        })
// console.log(event)
            
// console.log(event)
            showModal("newBlockModal/createContactModal")
    }
    const eventTrackforTikTok = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("name", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        });

        showModal("newBlockModal/createTiktokWidgetModal")
    }

    const eventTrackforInstagram = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("name", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        });

        showModal("newBlockModal/createInstagramWidgetModal")
    }

    const eventTrackforTwitter = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("name", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        })
        showModal("newBlockModal/createTwitterWidgetModal")
    }
    const eventTrackforYoutube = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("name", "Dynamic block clicked");
        formdata.append("project", slug);
        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        })

        showModal("newBlockModal/createYoutubeWidgetModal")

    }
    const showChildModal = (params) => {
        if (!isPro && (params.includes("Product"))) {
            handleClose()
            setShowProModal(!isPro);
            hideLoader()
            return
        }
        handleClose();
        history.push({
            pathname: `${basePath}/${mainPath}/${params}`,
            state: { showChild: true,
            parent_block_id: blockModal?.parent_id
            }
        })
    };
    return (
        <Modal
            centered
            show={show} onHide={handleClose} size={"lg"} id="newBlockModal">
            <div className=" modal-dialog-centered modal-mlarge" role="document">
                <div className="modal-content p-0" style={{borderRadius:"30px"}}>
                    <div className="modal-header create-biolink-header" style={{ border: "none", paddingBottom: "0px", paddingTop: '5px' }}>
                        {blockModal?.from == "folder_block" &&<h5 className="modal-title create-biolink-heading">Add To Folder</h5>}
                    </div>
                    <div className="modal-body" style={{padding: "0px"}}>
                        <div className="add-block-modal-body-main">
                            <div className="new-block-main-div block-nav-u">
                                {blockModal?.from == "folder_block"?
                                    <div className="row">
                                        <div className="new-block-div pe-0 mt-15">
                                            <img data-id="2" src={singlelink} onClick={() => showChildModal("newBlockModal/createLinkModal")} width="100%" />
                                        </div>
                                        <div className="new-block-div px-1 mt-15">
                                            <img data-id="5" src={productBlock} onClick={() => showChildModal("newBlockModal/createProductWidgetModal")} width="100%" />
                                        </div>
                                        <div className="new-block-div ps-0 mt-15 mb-u">
                                            <img data-id="11" src={coachingBlock} onClick={() => showChildModal("newBlockModal/createMeetingProductWidgetModal")} width="100%" />
                                        </div>
                                    </div>
                                : <>
                                <div className="row">
                                <div className="mt-u">

                                    <span className="add-block--modal-heading">Basics</span>
                                    <button type="button" onClick={handleClose} className="close-u" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L5 3.82149L8.57741 0.244078C8.90285 -0.0813592 9.43049 -0.0813592 9.75592 0.244078C10.0814 0.569515 10.0814 1.09715 9.75592 1.42259L6.17851 5L9.75592 8.57741C10.0814 8.90285 10.0814 9.43049 9.75592 9.75592C9.43049 10.0814 8.90285 10.0814 8.57741 9.75592L5 6.17851L1.42259 9.75592C1.09715 10.0814 0.569515 10.0814 0.244078 9.75592C-0.0813592 9.43049 -0.0813592 8.90285 0.244078 8.57741L3.82149 5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z" fill="#333333"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="1" src={linkblock} onClick={() => showModal("newBlockModal/createFolderModal")} width="100%" />
                                </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="2" src={singlelink} onClick={() => showModal("newBlockModal/createLinkModal")} width="100%" />
                                </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="4" src={textblock} onClick={() => showModal("newBlockModal/createTextBlockModal")} width="100%" />
                                </div>
                                </div>
                                <div className="row">
                                <div className="mt-u">
                                    <span className="add-block--modal-heading">Store</span>
                                </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="5" src={productBlock} onClick={() => showModal("newBlockModal/createProductWidgetModal")} width="100%" />
                                </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="11" src={coachingBlock} onClick={() => showModal("newBlockModal/createMeetingProductWidgetModal")} width="100%" />
                                </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="11" src={leadMagnetBlock} onClick={() => showModal("newBlockModal/createLeadMagnetWidgetModal")} width="100%" />
                                </div>
                                </div>
                                <div className="row">
                                    <div className="mt-u">
                                        <span className="add-block--modal-heading">Content</span>
                                    </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="9" src={embed} onClick={() => showModal("newBlockModal/createEmbedBlockModal")} width="100%" />
                                </div>
                                
                                {/* <div className="new-block-div"> */}
                                    {/* old working tiktok <img data-id="8" src="https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/21d09859-2c7f-4de9-aff9-d1dbba23a000/public" onClick={eventTrackforTwitter} width="100%" /> */}
                                    {/* blocked tiktok <img data-id="8" src="https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/cbb40f3b-4cd9-48fa-9cbe-b96bf7b53a00/public" width="100%" />
                                </div> */}
                                <div className="new-block-div mt-15">
                                    <img data-id="6" src={youtubeBlock} onClick={eventTrackforYoutube} width="100%" />
                                </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="7" src={tiktokBlock} onClick={eventTrackforTikTok} width="100%" />
                                </div>
                                <div className="new-block-div mt-15">
                                    <img src={zaapPost} data-id="11" onClick={() => showModal("newBlockModal/createZaapPostsModal")} width="100%" />
                                </div>
                                <div className="new-block-div mt-15">
                                    <img data-id="7" src={instagramBlock} onClick={eventTrackforInstagram} width="100%" />
                                </div>
                                </div>
                                <div className="row">
                                <div className="mt-u">
                                    <span className="add-block--modal-heading">Marketing</span>
                                </div>
                                <div className="col-4 new-block-div mt-15">
                                    <img data-id="3" src={emailBlock} onClick={() => showModal("newBlockModal/createEmailWidgetModal")} width="100%" />
                                </div>
                                <div className="new-block-div mt-15 mb-u">
                                    <img data-id="10" src={contactBlock} onClick={eventTrackforContact} width="100%" />
                                </div>
                               
                                </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showProModal && <Teammoadal showModal={showProModal} heading={'Upgrade to Unlock This Feature.'} text={' This is a PRO only Feature, Subscribe to<br /> PRO to unlock Analytics'} isUltra={false}/>}
        </Modal>
    )
}
export default BlockEditor