const INIT_STATE = {
scheduleTime : 'sendnow',
channel: 'both',
subject : null,
sender_email : null,
sender_name : null,
template_json : null,
editorjs_template: null,
cover_image: null,
channel: null,
cover_image_data_url:null,
checked_tags: [],
}
const newsletterModalReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_NEWSLETTER_MODAL':
            return action.payload 
        case 'RESET_NEWSLETTER_MODAL':
            return INIT_STATE
        default:
            return state
    }

}
export default newsletterModalReducer