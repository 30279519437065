import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { apiUrl } from "../utils/url";
const StripeConnectReturn = () => {
     const history = useHistory()
    const sendRequest = () => {
        localStorage.setItem("sendRequest", true)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        formdata.append("project", localStorage?.getItem("slug"))
        formdata.append("status", "success")
        const api = apiUrl + "/stripe-connect/callback";
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        fetch(api, setOptions).then(async(res)=>{
            localStorage.removeItem("sendRequest")
            const response = await res.json()
            if(response?.status_code == 200) {
                toast.success(response?.status_message)
                history.push(localStorage.last_activity)
            }
            else {
                toast.error(response?.status_message)
                history.push(localStorage.last_activity)
            }
                // window.close()      

        }).catch((error)=>{
            toast.error(error)
            // localStorage.setItem("stripe_connect_return", JSON.stringify({ stripe_error: true, message: error }))
            localStorage.removeItem("sendRequest")
            history.push(localStorage.last_activity)
                // window.close()
        })
    }
    if (!localStorage.sendRequest) {
        sendRequest()
    }
    else{
        return true
        
    }
   
    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default StripeConnectReturn