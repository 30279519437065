import React, { useEffect, useState } from 'react'
import ProductStep1 from './product_step_1'
import ProductStep2 from './productStep2'
import EditProduct from './edit-product'
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { hideLoader, showLoader } from '../loader';
import { apiUrl } from '../../utils/url';
import { toast } from 'react-toastify';

function ProductMain({from,closeProductType}) {
  const location = useLocation();
  const history = useHistory();
  const { slug, basePath } = useSelector((state) => state?.slugReducer);
  const { meet_token } = useSelector((state) => state?.meetConnectedReducer);
  const isPro = useSelector(state => state?.proReducer)
  const checkmain = location?.state;
  const [showProductStep1, setShowProductStep1] = useState(true)
  const [showProductStep2, setShowProductStep2] = useState(false)
  const [title, setTitle] = useState("");
  const [error, setError] = useState({});
  const [short_description, setShort_Description] = useState("");
  const [price_type, setPriceType] = useState("free");
  const [price, setPrice] = useState(0);
  const [min_price, setMin_Price] = useState(0);
  const [suggested_price, setSugested_Price] = useState(0);
  const [currency, setCurrency] = useState('usd');
  const [errorMessage, setErrorMessage] = useState('');
  const [product_slug, setProductSlug] = useState("");
  const [cover_image, setCover_Image] = useState([]);
  const [croppie_image, setcroppie_image] = useState({ url: "", id: null });
  const [croppie, setCroppie] = useState(null);
  const [deleted_cover_image, setDeleted_cover_image] = useState([])
  const [content, setContent] = useState("");
  const [openCalendar, setOpenCalendar] = useState(checkmain?.checkout1 == true ? true : false);
  const [opendigitalfile, setOpendigitalfile] = useState(checkmain?.checkout2 == true ? true : false);
  const [duration, setDuration] = useState("15");
  const [book_within_next_day, setBook_Within_Next_Day] = useState("7");
  const [custom_meeting_link, setCustomMeetingLink] = useState(null);
  const [timezone, setTimezone] = useState("");
  const [meeting_location, setMeetingLocation] = useState("");
  const [openVisitUrl, setOpenVisitUrl] = useState(false);
  const [urlPop, setUrlPop] = useState(true)
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [removepop, setRemoveopop] = useState(false);
  const [content_file, setContent_File] = useState();
  const [contentText, setContentText] = useState()
  const [content_url, setContent_Url] = useState('');
  const [faqs, setFaqs] = useState([{ questions: "",answers: "",},]);
  const [checkout_question, setCheckout_Question] = useState([{  questions: "",  isRequired: false,},]);
  const [product_theme, setProductTheme] = useState("darkmode");
  const [accent_theme, setAccent_Theme] = useState( "#9541FB");
  const [FAQ_heading, setFAQ_heading] = useState(null);
  const [buy_button_text, set_buy_button_text] = useState('Buy Now');
  const [days, setDays] = useState("Mon");
  const [break_between_meeting_before, setBreakBetweenMeetingBefore] = useState("15");
  const [break_between_meeting_after, setBreakBetweenMeetingAfter] = useState("20");
  const getDayName = (dayIndex) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
    return days[dayIndex];
  };
  const numberOfSlots = 8 ;
  const [defaultTimeSlots, setDefaultTimeSlots] = useState(
    Array.from({ length: 7 }, (_, dayIndex) => ({
        id: dayIndex,
        day: getDayName(dayIndex),
        slots: Array.from({ length: numberOfSlots }, (_, slotIndex) => ({
            id: `${dayIndex}-${slotIndex}`,
            time: 9 + slotIndex,
        })),
        showDropdown: false, // New property
    }))
);
    const nextStep = (e) => {
      e.preventDefault();
      showLoader();
      setError([]);
      const formdata = new FormData();
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
      const value = {
        title,
        short_description,
        product_slug,
        price_type,
        min_price,
        suggested_price,
        price,
      }
      for (const key in value) {
          if (Object.hasOwnProperty.call(value, key)) {
              formdata.append(key, value[key]);
          }
      }
      formdata.append('project', slug);
      let errorList = [];
      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
      };
      fetch(apiUrl + "/ProductFlow/slug-check", requestOptions)
          .then(async (res) => {
              hideLoader()
              const response = await res?.json();
              if (response?.status_code == 200) {
                setShowProductStep1(false)
                setShowProductStep2(true)
              }else{
                setError(Object.values(response?.errors));
                if (Object.keys(response?.errors).length > 0)
                  $(".modal-body").scrollTop(0);
                hideLoader();
              }
            })
            .catch(() => {
              hideLoader();
            });
    };
    useEffect(()=>{
      if (!isPro) {
        history.push(basePath + "/products")
      }
    },[])
    useEffect(() => {
      if(checkmain?.checkout1 == true){
       setContent("calender") 
      }
      else if(checkmain?.checkout2 == true){
        setContent("files")
      }
    },[]);
    async function handleSave() {
      showLoader();
      setError({});
      const obj = {
        faqs: JSON.stringify(faqs),
        currency,
        days,
        monTimeSlot: JSON.stringify(defaultTimeSlots[1].slots),
        tueTimeSlot: JSON.stringify(defaultTimeSlots[2].slots),
        wedTimeSlot: JSON.stringify(defaultTimeSlots[3].slots),
        thurTimeSlot: JSON.stringify(defaultTimeSlots[4].slots),
        friTimeSlot: JSON.stringify(defaultTimeSlots[5].slots),
        satTimeSlot: JSON.stringify(defaultTimeSlots[6].slots),
        sunTimeSlot: JSON.stringify(defaultTimeSlots[0].slots),
        title,
        short_description,
        product_slug,
        content,
        duration,
        timezone,
        book_within_next_day,
        break_between_meeting_before,
        break_between_meeting_after,
        meeting_location,
        price_type,
        product_theme,
        accent_theme,
        buy_button_text,
        FAQ_heading,
        content_file,
        content_url,
        price,
        min_price,
        checkout_question: JSON.stringify(checkout_question),
        suggested_price,
        deleted_cover_image,
        custom_meeting_link
      };
      // emailTemplateMarkup = template_json;
      // editorJSTemplateMarkup = editorjs_template;
  
      const api = apiUrl + "/ProductFlow/" + (from !== 'productType' ? "update" : "createProduct");
      const formdata = new FormData();
          for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          if (key === "content") {
            if (obj[key] === "files") {
                if (typeof ["content_file"] === "string") {
                    delete obj["content_file"];
                }
                delete obj["content_url"];
            }
          }
          formdata.append(key, obj[key]);
        }
      }
      formdata.append("project", slug);
      cover_image.map((val, key) => {
        // console.log("productTemplateMarkup", new File([val.img], "image.png"));
        if (val?.img) {
          formdata.append("cover_image[]", new File([val.img], "image.png"));
        }
      });
      // formdata.append("description", JSON.stringify(productTemplateMarkup));
      // formdata.append('template', emailTemplateMarkup);
      // formdata.append("editorjs_template", editorJSTemplateMarkup);
      // formdata.append('product_id',product_id ?? localStorage?.product_id);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage?.getItem("token")}`
      );
      const setOptions = {
        method: "POST",
        body: formdata,
        headers: myHeaders,
      };
      fetch(api, setOptions)
        .then(async (res) => {
          const response = await res?.json();
          if (response?.status_code == 200) {
            setShowProductStep2(false);
            setShowProductStep1(false);
            // if(from == 'productType'){
            //   closeProductType();
            // }
            history.push({
              pathname: basePath + "/editProduct",
              state:{
                edit:{ is: true, id: response?.id },
                product_id:response?.data?.product_id,
                fron:null
              }
            });
            hideLoader();
            toast.success(`Product ${from !== 'productType' ? 'Updated' : 'Created'} Successfully `)
          } else {
            setError(Object.values(response?.errors));
            if (Object.keys(response?.errors).length > 0)
              $(".modal-body").scrollTop(0);
            hideLoader();
          }
        })
        .catch(() => {
          hideLoader();
        });
    }
  return (
    <>
    {from !== 'productType' ?
        <EditProduct/> :
        <>
            <ProductStep1 showProductStep1={showProductStep1} setShowProductStep1={setShowProductStep1} nextStep={nextStep}  closeProductType={closeProductType} title={title} setTitle={setTitle }error={error} setError={setError }short_description={short_description} setShort_Description={setShort_Description }price_type={price_type} setPriceType={setPriceType }price={price} setPrice={setPrice }min_price={min_price} setMin_Price={setMin_Price }suggested_price={suggested_price} setSugested_Price={setSugested_Price} currency={currency} setCurrency={setCurrency}errorMessage={errorMessage} setErrorMessage={setErrorMessage }product_slug={product_slug} setProductSlug={setProductSlug}/>
            <ProductStep2 showProductStep2={showProductStep2} setShowProductStep2={setShowProductStep2} checkmain={checkmain} closeProductType={closeProductType}  cover_image={cover_image} setCover_Image={setCover_Image }croppie_image={croppie_image} setcroppie_image={setcroppie_image }croppie={croppie} setCroppie={setCroppie }deleted_cover_image={deleted_cover_image} setDeleted_cover_image={setDeleted_cover_image }content={content} setContent={setContent }openCalendar={openCalendar} setOpenCalendar={setOpenCalendar} opendigitalfile={opendigitalfile} setOpendigitalfile={setOpendigitalfile} duration={duration} setDuration={setDuration} book_within_next_day={book_within_next_day} setBook_Within_Next_Day={setBook_Within_Next_Day} custom_meeting_link={custom_meeting_link} setCustomMeetingLink={setCustomMeetingLink} meeting_location={meeting_location} setMeetingLocation={setMeetingLocation} openVisitUrl={openVisitUrl} setOpenVisitUrl={setOpenVisitUrl} urlPop={urlPop} setUrlPop={setUrlPop}selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} dropdownOpen={dropdownOpen} setDropdownOpen={setDropdownOpen} removepop={removepop} setRemoveopop={setRemoveopop} content_file={content_file} setContent_File={setContent_File} timezone={timezone} setTimezone={setTimezone} contentText={contentText} setContentText={setContentText} getDayName={getDayName} defaultTimeSlots={defaultTimeSlots} setDefaultTimeSlots={setDefaultTimeSlots} content_url={content_url} setContent_Url={setContent_Url} error={error} handleSave={handleSave}/>
        </>
    }
    </>
  )
}

export default ProductMain