import { useEffect } from "react";
import { useState } from "react";
import CommunityFooter from "../components/community/footer";
import HeaderSearch from "../components/community/headerSearch";
import { hideLoader, showLoader } from "../components/loader";
import PageHeader from "../components/page-header";
import SideBar from "../components/side-bar";
import { audiencePosts as getManageBillings } from "../services/postRequests/audience";
import { apiUrl } from "../utils/url";
import { audiencePosts as mangageStripePost } from "../services/postRequests/audience";
import { audiencePosts as manageSubscriptionPost } from "../services/postRequests/audience";
import "../assets/css/manage-billings.css"
import Skeleton from "react-loading-skeleton";
import unlinkPro from "../assets/img/unlink-pro-icon.svg";
import addPro from "../assets/img/add-pro-icon.svg";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

let params = [];
const ManageBillings = () => {
    const [filter, setfilter] = useState({ page: 1, search: null, project: localStorage.slug });
    const [manageProjects, setmanageProjects] = useState([]);
    const [loading, setloading] = useState(true)
    const history = useHistory();
    const callManageProjects = () => {
        setloading(false)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const api = apiUrl + `/project/manage?` + params?.join("&");
        const setOptions = {
            method: "GET",
            headers: myHeaders
        };
        getManageBillings(api, hideLoader, setOptions).then((response) => {
            if (response) {
                setmanageProjects(response)
                setloading(false)
            }
        })
    }

    useEffect(() => {
        params = [];
        for (const key in filter) {
            if (Object.hasOwnProperty.call(filter, key))
                if (filter[key]) params.push(`${[key]}=${filter[key]}`)
        };
        if (filter?.page) callManageProjects()

    }, [filter]);

    const mangageStripe = (e) => {
        showLoader()
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("project", e.target.dataset.value)
        const api = apiUrl + `/manage-subscription`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        mangageStripePost(api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        })
    }
    const manageSubscription = (e) => {
        showLoader()
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("tolt_referral", window.tolt_referral??'');
        formdata.append("project", e.target.dataset.value);
        formdata.append("plan_type", "pro");
        const api = apiUrl + `/project/link-unlink-pro/`+(e.target.dataset.value);
        const setOptions = {
            method: "POST",
            headers: myHeaders
        }
        manageSubscriptionPost(api, hideLoader, setOptions).then((response) => {
            if (response) {
                
// console.log(response);
                // hideLoader();
                showLoader()
                if(response?.url){
                    history.push(response?.url);
                    // localStorage.temp_slug = e.target.dataset.value
                    // localStorage.last_activity=location.pathname
                    // location.href = response.url
                }
                else{
                    callManageProjects();
                }
            }
        })
    }

    return (
        // <div id="wrapper">
        //     <PageHeader from="manage-billings" />
        //     <div class="community-main manage-billing-main">
        //         <SideBar from="manage-billings" />

                <section class="container h-100" style={{overflowY:"auto"}}>
                    <div className="container settings-section" style={{paddingLeft: "30px",paddingRight: "30px",paddingBottom: "22px"}}>
                        <h1 className="settings-section-heading">Billing</h1>

                    <div class="section-main w-100">
                                {
                                    loading ?
                                        <tbody>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">
                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody> :
                                        <div className="">
                                            {manageProjects?.projects?.length > 0 && (
                                                manageProjects?.projects?.map((val, index) => (
                                                    <div key={index} className="d-flex justify-content-between my-2 billings-data-div">
                                                        <div className="d-flex">
                                                            <p class="mb-0 d-inline-flex align-items-center justify-content-start" style={{gap:"10px"}}>

                                                                <div className="project-small-details d-flex align-items-start justify-content-center flex-column ps-2">
                                                                    <div className="project-small-user-pp">
                                                                        <img class="me-2 ms-2" src={val?.profile_picture} width="30px" alt="" />
                                                                        <span class="manage-projects-text ">{val?.title}</span>
                                                                    </div>
                                                                </div>
                                                                    <div class={`project-types ${val?.pro == 1 ? val?.plan_type == "ultra"?"ultra":(val?.plan_type == 'partner'|| val?.plan_type == 'lifetime'?' partner':" pro" ):"draft"} `}><p className="mb-0">{val?.pro == 1 ? (!val?.plan_type || val?.plan_type == 'pro_legacy' ? "Pro" : val?.plan_type.charAt(0).toUpperCase() + val.plan_type.slice(1)) :"Free"}</p></div>
                                                            </p>
                                                        </div>
                                                        {/* <td class="d-md-table-cell d-none">{setDateFormat(val?.created_at)}</td> */}
                                                            {
                                                            !(val?.plan_type == 'lifetime' && val?.pro == 1) ?
                                                            <>
                                                            <span class="d-flex align-items-center">
                                                                {val?.pro == 1  ? <p class="manage-btn mb-1 mt-1 badge badge-subscribed cursor-pointer" data-value={val?.slug} onClick={val?.pro == 1 && val?.manage_stripe == 1 ? mangageStripe : manageSubscription}>

                                                                    {/* <img src={unlinkPro} alt="" srcset="" data-value={val?.slug}/> */}
                                                                    <span  data-value={val?.slug}>{(val?.pro == 1 && val?.manage_stripe == 1 )?"Manage Subscription ":`Unlink ${val?.plan_type == "ultra" ? "Ultra":val?.plan_type == 'partner'?'Partner':val?.plan_type == 'lifetime'?'Subscription':'Pro'}`}</span>

                                                                </p>
                                                                    : <p class="manage-btn mb-1 me-2 badge me-2 badge-customer cursor-pointer" data-value={val?.slug} onClick={manageSubscription}>

                                                                        {/* <img src={addPro} alt="" srcset="" data-value={val?.slug}/> */}
                                                                        <span  data-value={val?.slug}>Upgrade</span>
                                                                    </p>
                                                                }
                                                            </span>
                                                            </> : null
                                                            }
                                                        {/* <td>
                                                            {(val?.pro == 1 && val?.manage_stripe == 1 ) && (
                                                                <button className="btn manage-billings me-3" data-value={val?.slug} onClick={mangageStripe}>Manage in Stripe</button>)
                                                            }
                                                        </td> */}
                                                        {/* <td class="d-md-table-cell d-none">{val?.opens}</td> */}

                                                    </div>
                                                )))}
                                        </div>
                                }
                                
                        {Math.abs(manageProjects?.available_pros) > 0 &&
                        <>
                         {/* {manageProjects?.projects && manageProjects?.projects.some(project => project.manage_stripe === 0 ) && (
                         <div class="section-header">
                             <h3 class="mb-0">Pro Projects Available</h3>
                         </div>
                         )} */}
                        {manageProjects?.projects && manageProjects?.projects.some(project => project.manage_stripe === 0) && (
                        <div className="d-flex my-4 justify-content-between">
                            {/* <div className="col-6"> */}
                            <div className="projects-availabiltiy-section-div ">
                                <div><b class="mb-0">Available Premium Projects</b><p>{manageProjects?.available_pros}</p></div>
                            </div>
                            {/* </div> */}
                            {/* <div className="col-6"> */}
                            <div className="projects-availabiltiy-section-div ">
                                <div><b class="mb-0">Total Premium Projects</b><p>{manageProjects?.total_pros}</p></div>
                            </div>
                            {/* </div> */}
                        </div>
                        )}
                        </>
                        }
                        <div class="section-footer d-flex align-items-center justify-content-between">
                        <CommunityFooter filter={filter} setfilter={setfilter} total={manageProjects?.paginated?.total} last_page={manageProjects?.paginated?.last_page} current_page={manageProjects?.paginated?.current_page}/>                        </div>
                    </div>

                    </div>
                </section>
        //     </div>

        // </div>
    )
}
export default ManageBillings;