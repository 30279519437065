import { toast } from "react-toastify"

export const audiencePosts = async (apiUrl, hideLoader, setOptions, isFrom) => {
    try {
        const res = await  fetch(apiUrl, setOptions)
        const response = await res.json();
        
// console.log("API Response", response)
        if (response?.status_code == 200) {
            if (isFrom == "export")  window.open(response?.data?.redirect_url, '_blank').focus();
            if(response?.status_message) toast?.success(response?.status_message);
            hideLoader();
            return await response?.data
        }
        else {
            toast?.error(response?.status_message)
            hideLoader();
            return false
        }
    }
    catch (error) {
        toast?.error(error)
        hideLoader()

        return false
    }
}