import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { hideLoader } from "../components/loader"
import { audiencePosts as oathPost } from "../services/postRequests/audience";
import { apiUrl } from "../utils/url";

const StripeCheckout = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const params = new URLSearchParams(window.location.search);
    const [status, setstatus] = useState(params?.get("status"));
    useEffect(() => {
        if (status) sendRequest();
    }, [])

  const redirectToPage = () => {

      setTimeout(() => {
          history.push(localStorage.last_activity)
          localStorage.removeItem("lastActivity")
        }, 1000);
    }

    const sendRequest = () => {
        if (localStorage.getItem("sendRequest")) return true
        localStorage.setItem("sendRequest", true)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        formdata.append("project", localStorage?.getItem("temp_slug") ? localStorage?.getItem("temp_slug") : localStorage?.getItem("slug"))
        formdata.append("status", status)
        formdata.append("plan_type", localStorage?.getItem("plan_type"))
        if(localStorage.getItem('subscription_from_walkthrough')){
            formdata.append("subscription_from_walkthrough", 1);
        }

        const api = apiUrl + "/stripe-checkout/callback";
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        oathPost(api, hideLoader, setOptions).then((response) => {
            if (response) {
                if(localStorage.getItem('subscription_from_walkthrough')){
                    localStorage.removeItem("lastStep")
                    localStorage.removeItem("currentStep")
                    localStorage.removeItem("walkthrough_step")
                    localStorage.setItem("walkthrough", 1)
                }
                localStorage.removeItem("sendRequest")
                dispatch({ type: "SET_PRO", payload: true })
                dispatch({ type: "SET_PlAN_TYPE", payload: localStorage?.getItem("plan_type") })
                window.dataLayer.push({
                    event: 'upgrade',
                    upgrade:localStorage?.getItem("plan_type") + (localStorage?.getItem("plan_type") != 'lifetime' ? ' '+localStorage?.getItem("period") : null)
                  });
                // window.opener.postMessage({ isPro: true });
                redirectToPage()
            }
            else {
                localStorage.removeItem("plan_type")
                
                dispatch({ type: "SET_PRO", payload: false })
                
                if(localStorage.getItem('subscription_from_walkthrough')){

                    localStorage.setItem("walkthrough", 0)
                    localStorage.setItem("lastActivity",'walkthrough?step=5');
                    history.push('walkthrough?step=5')
                }
                else{
                    localStorage.removeItem("lastActivity")
                    localStorage.removeItem("temp_slug")
                    redirectToPage()
                }
                // localStorage.removeItem("lastActivity")
                // window.opener.postMessage({ isPro: false });
                // setTimeout(() => {
                //     window.close()
                // }, 1000);

            }
            
            localStorage.removeItem("temp_slug")
        }).catch(() => {

            localStorage.setItem("walkthrough", 0)
            dispatch({ type: "SET_PRO", payload: false })
            if(localStorage.getItem('subscription_from_walkthrough')){
                localStorage.setItem("lastActivity",'walkthrough?step=5');
            }
            else{
                localStorage.removeItem("lastActivity")
                localStorage.removeItem("temp_slug")
            }
            redirectToPage()
            // window.opener.postMessage({ isPro: false });
            // setTimeout(() => {
            //     window.close()
            // }, 1000);
        })
    }

    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default StripeCheckout