import copyClipboard from "../assets/img/copy-clipboard.svg"
import analyticsUp from "../assets/img/analytics-up.svg"
import pageActivity from "../assets/img/page-activity.svg"
import forwardArrow from "../assets/img/forward-arrow.svg"
import instaIcon from "../assets/img/insta-icon.svg"
import tiktokIcon from "../assets/img/tiktok-icon.svg"
import readOurTutorialsIcon from "../assets/img/read-our-tutorials.svg"
import communityGraph from "../assets/img/community-graph.svg"
// import SideBar from "../components/side-bar/dashboard";
import NewSideBar from "../components/side-bar/new-sidebar";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import "../assets/css/dashboard.css";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import { showLoader, hideLoader } from "../components/loader";
import DashboardProjectsModal from "../components/dashboard/projects/modal";
import { apiUrl, pageUrl, protocol } from "../utils/url";
import { toast } from "react-toastify";
import goldImg from "../assets/img/gold.svg"
import NewPageHeader from "../components/new-page-header"
import calenderImage from "../assets/media/dashboard/calendar.png"
import chainImage from "../assets/media/dashboard/chain.svg"
import funnelImage from "../assets/media/dashboard/funnel.svg"
import targetImage from "../assets/media/dashboard/target.svg"
import revenueImage from "../assets/media/dashboard/revenue.png"
import goldPlateImage from "../assets/media/dashboard/gold-plate.svg"
import heartImage from "../assets/media/dashboard/heart.svg"
import calendarHalfImage from "../assets/media/dashboard/calendar-half.svg"
import chevron_down from "../assets/img/modals/chevron-down.svg"
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
} from 'chart.js';
import { months, options, originalOptionsForDashboardGraph } from "../utils/analytics/line"
import { useDispatch, useSelector } from "react-redux"
import NewBottomBar from "../components/side-bar/new-bottombar"
import { audiencePosts as apiService } from "../services/postRequests/audience";
import * as $ from "jquery";
import setDateFormat from "../utils/setDateFormat"
import analyticsDown from "../assets/img/analytics-down.svg"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import { DashboardHeader } from "../components/dashboard/projects/DashboardHeader"
import WelcomeModal from "../components/page-editor/welcome-modal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
);


const NewDashboard = () => {
    let queryParams = []
    // const { isLoading, projects, basePath, selectedProfile } = useSelector(state => state?.sidebarReducer);
    const { state } = useLocation();
    const history = useHistory();
    if (state && state?.welcomeModalOpen) {
        return <WelcomeModal />;
      }
    const [isLoading, setisLoading] = useState(true)
    const [statsLoading, setstatsLoading] = useState(true)
    const [isProjectLoading, setisProjectLoading] = useState(true)
    const [projects, setProjects] = useState([]);
    const [showModal, setShowModal] = useState({ show: false, duplicate: false });
    const [basePath, setbasePath] = useState(null)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [selectedSlug, setSelectedSlug] = useState(null)
    const [stats, setStats] = useState([])
    const dispatch = useDispatch();
    const [selectedProject, setSelectedProject] = useState(true)
    const [sharedProjects, setSharedProjects] = useState([]);
    const [hoveredCard, setHoveredCard] = useState("text-light");
    const [mobileScreen, setMobileScreen] = useState(false);
    const [lastDays, setlastDays] = useState("Last 7 Days");
    // const getProjects = () => {
    //     showLoader();
    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

    //     fetch(apiUrl + "/project/get-all", { headers: myHeaders })
    //         .then(async (res) => {
    //             const response = await res.json();
    //             console.log("response", response)
    //             dispatch({ type: "SET_NEW_USER", payload: response?.data.projects[0]?.new_user == 1 ? true : false });
    //             dispatch({ type: "SET_PRO", payload: response?.data.projects[0]?.pro == 1 ? true : false });
    //              setProjects(response?.data.projects)
    //             setSharedProjects(response?.data?.shared_projects)
    //             setSelectedProfile(response?.data.projects[0]?.id)
    //             setSelectedSlug(response?.data.projects[0]?.slug)
    //             setbasePath("/project/" + response?.data.projects[0]?.slug)
    //             setSelectedProject(true)
    //             setisProjectLoading(false)
    //             setisLoading(false)
    //             hideLoader()
    //         }).catch((err) => {
    //             console.log(err)
    //             setisProjectLoading(false)
    //             hideLoader()
    //         })

    //     hideLoader()
    // }
    const handleCloseModal = () => setShowModal({ show: false, duplicate: false })
    const handleOpenModal = () => setShowModal({ show: true, duplicate: false })
    const copyingText = (text) => navigator.clipboard.writeText(text).then((r => toast.success("Successfully Copied")))
    const order = Math.min((stats?.order_sum / 10), 100);
    function checkScreenSize() {
        if (window.innerWidth <= 768) {
            setMobileScreen(true);
        }else{
            setMobileScreen(false);
        }
    }
    useEffect(()=>{
        window.addEventListener('resize', checkScreenSize);
        
        window.addEventListener('load', checkScreenSize);    
    },[])
    // useEffect(() => {
    //     if (projects?.length == 0)
    //         getProjects()
    //     else localStorage.removeItem("sendRequest")
    // }, []);

    let graph_dates = new Date();
    var labels = []

    // for (let index = 1; index < 7; index++) {
    //     graph_dates.setDate(graph_dates.getDate() - 1);
    //     labels.unshift(months[graph_dates.getMonth()] + ' ' + graph_dates.getDate());
    // }
    const [dataUnique, setDataUnique] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Unique Visitors',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
            //    backgroundColor: gradient,
               backgroundColor: 'rgba(12, 140, 251, 0.25)',
                // tension: 0.1,
                fill:true,
                // fill:{
                //     target: 0,
                //     below: "rgba(12, 140, 251, 0.25)"
                // }
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill:true,
                // fill:{
                //     target:  'start',
                //     below: "rgba(127, 72, 251, 0.25)"
                // }
            },
        ]
    })
    const [dataImpressions, setDataImpressions] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Imperessions',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill:true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill:true,
            },
        ]
    })
    const [dataMeetings, setDataMeetings] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Meetings',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill:true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill:true,
            },
        ]
    })
    const [dataSales, setDataSales] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Sales',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill:true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill:true,
            },
        ]
    })
    const [dataRevenue, setDataRevenue] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Revenue',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill:true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill:true,
            },
        ]
    })
    const [dataSubscribers, setDataSubscribers] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Subscribers',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill:true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill:true,
            },
        ]
    })
    const handleCardHover = (name) => {
        setHoveredCard(name);
    }
    // var today = new Date();
    // var today_text = months[today.getMonth()] + ' ' + today.getDate();

    // var week_before = new Date();
    // week_before.setDate(week_before.getDate() - 7);
    // var week_before_text = months[week_before.getMonth()] + ' ' + week_before.getDate();
    // var first_date, first_date_text = '', last_date, last_date_text = '';

    // const renderCalendar = () => {
    //     var myCalendar = $('#calendar-picker').mobiscroll().datepicker({
    //         controls: ['calendar'],
    //         display: 'inline',
    //         calendarSize: 1,
    //         selectMultiple: true,
    //         selectMin: 1,
    //         selectMax: 2,
    //         returnFormat: 'locale',
    //         themeVariant: 'light',
    //         theme: 'ios',
    //         onChange: function (event, inst) {
    //             // console.log("event",event);
    //             if (event.value[0]) {
    //                 first_date = new Date(event.value[0]);
    //                 first_date_text = months[first_date.getMonth()] + ' ' + first_date.getDate();
    //             }
    //             if (event.value[1]) {
    //                 last_date = new Date(event.value[1]);
    //                 last_date_text = months[last_date.getMonth()] + ' ' + last_date.getDate();
    //             }
    //             if (first_date < last_date) {
    //                 $(".date-filter-dropdown-menu-selected").text(first_date_text + ' - ' + last_date_text);
    //             } else {
    //                 $(".date-filter-dropdown-menu-selected").text(last_date_text + ' - ' + first_date_text);
    //             }
    //             if (event?.value.length > 1) {
    //                 // console.log("first_date",first_date,"last_date",last_date)
    //                 var timeDiff = Math.abs(first_date - last_date);
    //                 var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    //                 // console.log("diffDays",diffDays);
    //                 if (first_date > last_date) {
    //                     last_date = event.value[0];
    //                     first_date = event.value[1];
    //                 }
    //                 if (diffDays != 7 || first_date > today) {
    //                     myCalendar.setVal([week_before, today]);
    //                 }else {
    //                     // setData({labels:[first_date_text,last_date_text]});
    //                     getStats(null, null, localStorage?.slug, setDateFormat(first_date, false, true), setDateFormat(last_date, false, true))
    //                 }
    //             }
    //         }
    
    //     }).mobiscroll('getInst');

    //     // alert(today);
    //     setTimeout(() => {
    //         // myCalendar?.setVal([week_before, today]);
    //     }, 1500);
    // }
    const renderCalendar = (name = "last_7_days",dashboardLoad=true) =>{
        var first_date = new Date();
        var last_date = new Date();
        if(name == "last_7_days"){
            setlastDays("Last 7 Days");
            last_date.setDate(first_date.getDate() - 7);
            first_date = setDateFormat(first_date, false, true);
            last_date = setDateFormat(last_date, false, true);
            getStats(null, null, localStorage?.slug, first_date, last_date,dashboardLoad,"7 days")
        }else if(name == "last_14_days"){
            setlastDays("Last 14 Days");
            last_date.setDate(first_date.getDate() - 14);
            first_date = setDateFormat(first_date, false, true);
            last_date = setDateFormat(last_date, false, true);
            getStats(null, null, localStorage?.slug, first_date, last_date,dashboardLoad,"14 days")
        }
        else if(name == "last_30_days"){
            setlastDays("Last 30 Days");
            last_date.setDate(first_date.getDate() - 30);
            first_date = setDateFormat(first_date, false, true);
            last_date = setDateFormat(last_date, false, true);
            getStats(null, null, localStorage?.slug, first_date, last_date,dashboardLoad,"30 days")
        }
    }
    useEffect (() => {
        if (!isLoading) {

            // $('.date-filter-dropdown-menu').on('click', function (e) {
            //     e.stopPropagation();
            // });
            // $(".date-filter-dropdown-menu-selected").text(week_before_text + ' - ' + today_text);
            renderCalendar("last_7_days",true);

        }
    }, [isLoading])
    const getStats = (e, id, slug,from_date,to_date,dashboardLoad,days) => {
        showLoader();
        let temp_graph_data_unique = []
        let temp_graph_data_impressions = []
        let temp_graph_data_meetings = []
        let temp_graph_data_sales = []
        let temp_graph_data_revenue = []
        let temp_graph_data_subscribers = []
        queryParams = []
        queryParams = "?from_date=" + from_date + "&to_date=" + to_date + '&dashboard_load=' + dashboardLoad + '&days=' + days;
        e && e.preventDefault();
        const api = apiUrl + "/project/get-dashboard/" + slug;
        var myHeaders = new Headers();
        
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        apiService(api + queryParams, hideLoader, setOptions)?.then((response) => {
            if (response) {
                // console.log("responses",response);
                setstatsLoading(false);
                setStats(response);
                setSelectedProfile(response?.project?.id)
                setbasePath("/project/" + slug)
                dispatch({ type: "SET_NEW_USER", payload: response?.new_user == 1 ? true : false });
                dispatch({ type: "SET_PRO", payload: response?.pro == 1 ? true : false });
                dispatch({ type: "SET_PLAN_TYPE", payload: response?.plan_type });

                var ctx = document?.getElementById("analytics-main-chart")?.getContext("2d");
                var gradient = ctx?.createLinearGradient(0, 0, 0, 400);
                gradient?.addColorStop(0, 'rgba(12, 140, 251, 0.25)');
                gradient?.addColorStop(1, 'rgba(12, 140, 251, 0.00)');
                var gradientPast = ctx?.createLinearGradient(0, 0, 0, 400);
                gradientPast?.addColorStop(0, 'rgba(127, 72, 251, 0.25)');
                gradientPast?.addColorStop(1, 'rgba(127, 72, 251, 0.00)');
                temp_graph_data_unique.push(
                    {
                        label: 'Unique Visitors',
                        fill: true,
                        borderColor: '#0C8CFB',
                        backgroundColor:gradient,
                        tension: 0.1,
                        data: response?.graph_data?.unique_visitors
                    },
                    {
                        label: 'Past Periods',
                        data: response?.past_graph_data?.unique_visitors,
                        borderColor: '#7F48FB',
                        backgroundColor: gradientPast,
                        tension: 0.1,
                        fill:true,
                    },
                );
                setDataUnique({ labels: response?.graph_data?.labels, datasets: temp_graph_data_unique,  });
                temp_graph_data_impressions.push(
                    {
                        label: 'Past Period',
                        fill: true,
                        borderColor: '#0C8CFB',
                        backgroundColor:gradient, 
                        tension: 0.1,
                        data: response?.graph_data?.impressions
                    },
                    {
                        label: 'Past Periods',
                        data: response?.past_graph_data?.impressions,
                        borderColor: '#7F48FB',
                        backgroundColor:gradientPast,
                        tension: 0.1,
                        fill:true,
                    },
                )
                setDataImpressions({ labels: response?.graph_data?.labels, datasets: temp_graph_data_impressions,  });
                temp_graph_data_meetings.push(
                    {
                        label: 'Past Period',
                        fill: true,
                        borderColor: '#0C8CFB',
                        backgroundColor:gradient,
                        tension: 0.1,
                        data: response?.graph_data?.meetings
                    },
                    {
                        label: 'Past Periods',
                        data: response?.past_graph_data?.meetings,
                        borderColor: '#7F48FB',
                        backgroundColor:gradientPast,
                        tension: 0.1,
                        fill:true,
                    },
                )
                setDataMeetings({ labels: response?.graph_data?.labels, datasets: temp_graph_data_meetings,  });
                temp_graph_data_sales.push(
                    {
                        label: 'Past Period',
                        fill: true,
                        borderColor: '#0C8CFB',
                        backgroundColor:gradient, 
                        tension: 0.1,
                        data: response?.graph_data?.sales
                    },
                    {
                        label: 'Past Periods',
                        data: response?.past_graph_data?.sales,
                        borderColor: '#7F48FB',
                        backgroundColor:gradientPast,
                        tension: 0.1,
                        fill:true,
                    },
                )
                setDataSales({ labels: response?.graph_data?.labels, datasets: temp_graph_data_sales,  });
                temp_graph_data_revenue.push(
                    {
                        label: 'Past Period',
                        fill: true,
                        borderColor: '#0C8CFB',
                        backgroundColor:gradient,
                        tension: 0.1,
                        data: response?.graph_data?.revenue
                    },
                    {
                        label: 'Past Periods',
                        data: response?.past_graph_data?.revenue,
                        borderColor: '#7F48FB',
                        backgroundColor:gradientPast,
                        tension: 0.1,
                        fill:true,
                    },
                )
                setDataRevenue({ labels: response?.graph_data?.labels, datasets: temp_graph_data_revenue,  });
                temp_graph_data_subscribers.push(
                    {
                        label: 'Past Period',
                        fill: true,
                        borderColor: '#0C8CFB',
                        backgroundColor:gradient,
                        tension: 0.1,
                        data: response?.graph_data?.subscribers
                    },
                    {
                        label: 'Past Periods',
                        data: response?.past_graph_data?.subscribers,
                        borderColor: '#7F48FB',
                        backgroundColor:gradientPast,
                        tension: 0.1,
                        fill:true,
                    },
                )
                setDataSubscribers({ labels: response?.graph_data?.labels, datasets: temp_graph_data_subscribers,  });
                hideLoader();
            }
        })
    }
        // useEffect(() => {
        //     if (window.location.pathname.includes("/dashboard")) {
        //         if (selectedSlug) {
        //             renderCalendar()
                    // getStats(null, null, localStorage?.slug)
        //         }
        //     }
        // },[selectedSlug])
        // $(document).ready(function(){
        //     $(".card").hover(function(){
        //         var color = $(this).data("color");

        //         $('.project-details-main').stop().animate({
                    
        //         }, 500, function() {
        //             $('.project-details-main').css({
        //                 background: `linear-gradient(0deg, #FFF 0%, #${color} 100%)`,
        //                 transition: 'background 0.5s ease, opacity 0.5s ease',
        //             });
        //         });
        //     }, function() {
        //         $('.project-details-main').stop().animate({
        //             // opacity: 1 
        //         }, 500, function() {
        //             $('.project-details-main').css({
        //                 background: 'linear-gradient(0deg, #FFF 0%, #fff 100%)'
        //             });
        //         });
        //     });
        // });


        
        $(document).ready(function(){
            $(".card").mouseenter(function(){
                // var hoveredClass = $(this).data('color')
                $(".views-card-hover").animate({opacity:1},1000);
                // $('.fade-color').addClass(hoveredClass);
            });

            $(".card").mouseleave(function(){
                // var hoveredClass = $(this).data('color')
                
                $(".views-card-hover").animate({opacity:0},1000);
            });
        });
        const redirctToSettings = (pageName) => {
            history.push({
                pathname:`/account-settings`,
                state: { defaultActivePage: pageName }}
            )
        }
    return (
        <div id="wrapper">
            <PageHeader from="dashboard" isPro={stats?.pro} plan={stats?.plan_type} projects={projects}  selectedProfile={selectedProfile}/>
            <div className="dashboard-main new-dashboard">
                 <NewSideBar welcomeModalOpen={state?.welcomeModalOpen} newAcccountCreated={state?.sidebarOpen} renderCalendar={renderCalendar}selectedProject={selectedProject}  setSelectedProject={setSelectedProject} sharedProjects={sharedProjects} setSharedProjects={setSharedProjects} from={'dashboard'} projects={projects} setProjects={setProjects} isLoading={isLoading} setisLoading={setisLoading} setStats={setStats} basePath={basePath} setbasePath={setbasePath} selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile} selectedSlug={selectedSlug} setSelectedSlug={setSelectedSlug} newProjectcreated={state?.newProjectcreated}/>
                {/* <SideBar setShowModal={setShowModal} setSelectedProfile={setSelectedProfile} getProjects={getProjects} selectedProfile={selectedProfile} isLoading={isProjectLoading} projects={projects} handleOpenModal={handleOpenModal} setbasePath={setbasePath} setStats={setStats} isPro={stats?.pro} selectedSlug={selectedSlug} /> */}
                {!isLoading ?
                <>




                    <section className="dashboard-section">
                        <DashboardHeader projects={selectedProject ? projects:sharedProjects} selectedProfile={selectedProfile} stats={stats} selectedProject={selectedProject}/>
                        <div className="container dashboard-container">
                            <div className="affiliate-div">
                                <div>
                                    <h2 className="m-0">Share Zaap. Get Paid.</h2>
                                    <p className="m-0">Refer creators and earn 25% profit share every month.</p>
                                </div>
                                <div>
                                    <a href="https://www.zaap.ai/learn/how-to-become-a-zaap-affiliate" target="_blank" className="btn-grey settings-button-primary me-2">
                                        FAQ’s 
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                            <path d="M1 7L7 1M7 1H3M7 1V5" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        </span>
                                    </a>
                                    <button onClick={()=>redirctToSettings('affiliate-program')} className="blue-shadow-button">
                                        Get Unique Link
                                    </button>
                                </div>
                            </div>
                        <div className="dashboard-analytics d-flex">
                            <div className="date-btns-selector">
                                <div className={`date-filter-button me-2 ${lastDays == "Last 7 Days" ? 'active':''}`} onClick={()=>{renderCalendar("last_7_days",false)}} name="last_7_days">Last 7 Days</div>
                                <div className={`date-filter-button me-2 ${lastDays == "Last 14 Days" ? 'active':''}`}  onClick={()=>{renderCalendar("last_14_days",false)}} name="last_14_days">Last 14 Days</div>
                                <div className={`date-filter-button ${lastDays == "Last 30 Days" ? 'active':''}`}  onClick={()=>{renderCalendar("last_30_days",false)}} name="last_30_days">Last 30 Days</div>
                                {/* <div class="ms-1 date-filter-dropdown dropdown-toggle noselect"
                                    id="date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="date-filter-dropdown-menu-selected font-weight-500">All</span>
                                    <img src={chevron_down} width="10px" alt="" class="ms-1" />
                                </div> */}

                                {/* <ul class="dropdown-menu date-filter-dropdown-menu" style={{width:'20%'}} aria-labelledby="date-filter-dropdown" onClick={e => e.stopPropagation()}>
                                    <li>
                                        <div id="calendar-picker"></div>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                    <div className="analytics-card">
                                        <div className="card-name">
                                                Unique Visitors
                                        </div>

                                        <div className="analytics-details d-flex py-2">
                                            <div class="analytics-detail analytics-card-blue">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analytics?.ProjectStatsUniqueAnalytics}</h1>
                                                    {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                    <span class={`analytics-badge ${stats?.analytics?.UniqueAnalyticsPercent[1] == 'up' ?  'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                        {stats?.analytics?.UniqueAnalyticsPercent[0]  != '-'  && <img src={stats?.analytics?.UniqueAnalyticsPercent[1] == 'up' ?analyticsUp:analyticsDown} width="7px" alt="" />}
                                                        <span class={`${stats?.analytics?.UniqueAnalyticsPercent[0]  != '-' ? 'ms-1':''}`}>{stats?.analytics?.UniqueAnalyticsPercent[0]  != '-' ? stats?.analytics?.UniqueAnalyticsPercent[0]+'%':'-'}</span>
                                                    </span>
                                                    }
                                                </div>
                                                <h5 class="mb-0 font-weight-500">{lastDays}</h5>
                                            </div>
                                            <div class="analytics-detail analytics-card-purple">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analyticsPastPeriods?.ProjectStatsUniqueAnalyticsPastPeriod}</h1>

                                                </div>
                                                <h5 class="mb-0 font-weight-500">Previous period</h5>
                                            </div>
                                        </div>
                                        <div className="card-analytics-container mt-4">
                                            <Line id="analytics-main-chart" 
                                            data={dataUnique} options={originalOptionsForDashboardGraph}
                                            width={"293px"} height={'199px'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                    <div className="analytics-card">
                                        <div className="card-name">
                                                Impressions
                                        </div>

                                        <div className="analytics-details d-flex py-2">
                                            <div class="analytics-detail analytics-card-blue">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analytics?.ProjectStatsImpressionsAnalytics}</h1>
                                                    {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                    <span class={`analytics-badge ${stats?.analytics?.ImpressionsAnalyticsPercent[1] == 'up' ?  'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                        {stats?.analytics?.ImpressionsAnalyticsPercent[0]  != '-' && <img src={stats?.analytics?.ImpressionsAnalyticsPercent[1] == 'up' ?analyticsUp:analyticsDown} width="7px" alt="" />}
                                                        <span class={`${stats?.analytics?.ImpressionsAnalyticsPercent[0]  != '-' ? 'ms-1':''}`}>{stats?.analytics?.ImpressionsAnalyticsPercent[0]  != '-' ? stats?.analytics?.ImpressionsAnalyticsPercent[0]+'%':'-'}</span>
                                                    </span>
                                                    }
                                                </div>
                                                <h5 class="mb-0 font-weight-500">{lastDays}</h5>
                                            </div>
                                            <div class="analytics-detail analytics-card-purple">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analyticsPastPeriods?.ProjectStatsImpressionsAnalyticsPastPeriod}</h1>

                                                </div>
                                                <h5 class="mb-0 font-weight-500">Previous period</h5>
                                            </div>
                                        </div>
                                        <div className="card-analytics-container mt-4">
                                            <Line id="analytics-main-chart"
                                                data={dataImpressions} options={originalOptionsForDashboardGraph}
                                                width={"293px"} height={'199px'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                    <div className="analytics-card">
                                        <div className="card-name">
                                                Meetings
                                        </div>

                                        <div className="analytics-details d-flex py-2">
                                            <div class="analytics-detail analytics-card-blue">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analytics?.ProjectStatsMeetingsAnalytics}</h1>
                                                    {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                    <span class={`analytics-badge ${stats?.analytics?.MeetingsAnalyticsPercent[1] == 'up' ?  'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                        {stats?.analytics?.MeetingsAnalyticsPercent[0]  != '-'  && <img src={stats?.analytics?.MeetingsAnalyticsPercent[1] == 'up' ?analyticsUp:analyticsDown} width="7px" alt="" />}
                                                        <span class={`${stats?.analytics?.MeetingsAnalyticsPercent[0]  != '-' ? 'ms-1':''}`}>{stats?.analytics?.MeetingsAnalyticsPercent[0]  != '-' ? stats?.analytics?.MeetingsAnalyticsPercent[0]+'%':'-'}</span>
                                                    </span>
                                                    }
                                                </div>
                                                <h5 class="mb-0 font-weight-500">{lastDays}</h5>
                                            </div>
                                            <div class="analytics-detail analytics-card-purple">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analyticsPastPeriods?.ProjectStatsMeetingsAnalyticsPastPeriod}</h1>

                                                </div>
                                                <h5 class="mb-0 font-weight-500">Previous period</h5>
                                            </div>
                                        </div>
                                        <div className="card-analytics-container mt-4">
                                            <Line id="analytics-main-chart"
                                                data={dataMeetings} options={originalOptionsForDashboardGraph}
                                                width={"293px"} height={'199px'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                    <div className="analytics-card">
                                        <div className="card-name">
                                                Sales
                                        </div>

                                        <div className="analytics-details d-flex py-2">
                                            <div class="analytics-detail analytics-card-blue">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analytics?.ProjectStatsSalesAnalytics}</h1>
                                                    {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                    <span class={`analytics-badge ${stats?.analytics?.SubscriberAnalyticsPercent[1] == 'up' ?  'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                        {stats?.analytics?.SubscriberAnalyticsPercent[1] != '-'&&<img src={stats?.analytics?.SubscriberAnalyticsPercent[1] == 'up' ?analyticsUp:analyticsDown} width="7px" alt="" />}
                                                        <span class={`${stats?.analytics?.SubscriberAnalyticsPercent[0] != '-' ? 'ms-1':''}`}>{stats?.analytics?.SubscriberAnalyticsPercent[0] != '-' ? stats?.analytics?.SubscriberAnalyticsPercent[0]+'%':'-'}</span>
                                                    </span>
                                                    }
                                                </div>
                                                <h5 class="mb-0 font-weight-500">{lastDays}</h5>
                                            </div>
                                            <div class="analytics-detail analytics-card-purple">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analyticsPastPeriods?.ProjectStatsSalesAnalyticsPastPeriod}</h1>

                                                </div>
                                                <h5 class="mb-0 font-weight-500">Previous period</h5>
                                            </div>
                                        </div>
                                        <div className="card-analytics-container mt-4">
                                            <Line id="analytics-main-chart"
                                                data={dataSales} options={originalOptionsForDashboardGraph}
                                                width={"293px"} height={'199px'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                    <div className="analytics-card">
                                        <div className="card-name">
                                                Revenue
                                        </div>

                                        <div className="analytics-details d-flex py-2">
                                            <div class="analytics-detail analytics-card-blue">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analytics?.ProjectStatsRevenueAnalytics}</h1>
                                                    {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                    <span class={`analytics-badge ${stats?.analytics?.RevenueAnalyticsPercent[1] == 'up' ?  'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                        {stats?.analytics?.RevenueAnalyticsPercent[1] != '-' && <img src={stats?.analytics?.RevenueAnalyticsPercent[1] == 'up' ?analyticsUp:analyticsDown} width="7px" alt="" />}
                                                        <span class={`${stats?.analytics?.RevenueAnalyticsPercent[0] != '-' ? 'ms-1':''}`}>{stats?.analytics?.RevenueAnalyticsPercent[0] != '-' ? stats?.analytics?.RevenueAnalyticsPercent[0]+'%' : '-'}</span>
                                                    </span>
                                                    }
                                                </div>
                                                <h5 class="mb-0 font-weight-500">{lastDays}</h5>
                                            </div>
                                            <div class="analytics-detail analytics-card-purple">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analyticsPastPeriods?.ProjectStatsRevenueAnalyticsPastPeriod}</h1>

                                                </div>
                                                <h5 class="mb-0 font-weight-500">Previous period</h5>
                                            </div>
                                        </div>
                                        <div className="card-analytics-container mt-4">
                                            <Line id="analytics-main-chart"
                                                data={dataRevenue} options={originalOptionsForDashboardGraph}
                                                width={"293px"} height={'199px'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                    <div className="analytics-card">
                                        <div className="card-name">
                                                Subscribers
                                        </div>

                                        <div className="analytics-details d-flex py-2">
                                            <div class="analytics-detail analytics-card-blue">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analytics?.ProjectStatsSubscriberAnalytics}</h1>
                                                    {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                    <span class={`analytics-badge ${stats?.analytics?.SubscriberAnalyticsPercent[1] == 'up' ?  'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                        {stats?.analytics?.SubscriberAnalyticsPercent[1] != '-' && <img src={stats?.analytics?.SubscriberAnalyticsPercent[1] == 'up' ?analyticsUp:analyticsDown} width="7px" alt="" />}
                                                        <span class={`${stats?.analytics?.SubscriberAnalyticsPercent[0] != '-' ? 'ms-1':''}`}>{stats?.analytics?.SubscriberAnalyticsPercent[0] != '-' ? stats?.analytics?.SubscriberAnalyticsPercent[0]+'%' : '-'}</span>
                                                    </span>
                                                    }
                                                </div>
                                                <h5 class="mb-0 font-weight-500">{lastDays}</h5>
                                            </div>
                                            <div class="analytics-detail analytics-card-purple">
                                                <div class="d-flex align-items-center justify-content-start">
                                                    <h1 class="me-2">{stats?.analyticsPastPeriods?.ProjectStatsSubscriberAnalyticsPastPeriod}</h1>

                                                </div>
                                                <h5 class="mb-0 font-weight-500">Previous period</h5>
                                            </div>
                                        </div>
                                        <div className="card-analytics-container mt-4">
                                            <Line id="analytics-main-chart"
                                                data={dataSubscribers} options={originalOptionsForDashboardGraph}
                                                width={"293px"} height={'199px'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    </>
                    :
                    <section className="dashboard-section">

                        <div className="project-details-main" style={{background:`linear-gradient(0deg, #FFF 0%, #fff 100%)`}}>
                        <div className="container dashboard-container" style={{marginTop:"45px"}}>
                        <div className="d-flex align-items-center" style={{ width: "-webkit-fill-available" }}>
                            <div className="project-user-pp">
                                <Skeleton
                                    circle
                                    height={70}
                                    width={70}
                                    containerClassName="avatar-skeleton"
                                />
                            </div>
                            <div className="project-details d-flex align-items-start justify-content-center flex-column ps-2">
                                <div className="project-title d-flex align-items-center">
                                    <Skeleton width={100} className="ms-2 zaap-badge badge-free" />
                                    <Skeleton width={50} className="ms-2 zaap-badge badge-free" />
                                </div>
                                <div className="project-link d-flex align-items-center">
                                    <Skeleton width={150} className="text-muted-light ms-2" />
                                    <Skeleton count={1} className="ms-3 mt-1 copy-clipboard" />
                                </div>
                            </div>
                        </div>
                            <div className="cards-container">
                                <div className="views-card card" onMouseEnter={()=>handleCardHover("EFEFFF")}>
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Views
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#7F48FB"
                                                highlightColor="#6e36ed"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                    </div>
                                    <div className="views-card-pic-div">
                                        <img className="views-card-image" src={chainImage} alt="" />
                                    </div>
                                </div>
                                <div className="clicks-card card" onMouseEnter={()=>handleCardHover("E5FFFF")}>
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Click
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#48FBFB"
                                                highlightColor="#74c3c3"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                    </div>
                                    <div className="clicks-card-pic-div">
                                        <img className="clicks-card-image" src={funnelImage} alt="" />
                                    </div>
                                </div>
                                <div className="sales-card card" onMouseEnter={()=>handleCardHover("FFE9E1")}>
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Sales
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#FB7348"
                                                highlightColor="#e35629"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                    </div>
                                    <div className="sales-card-pic-div">
                                        <img className="sales-card-image" src={targetImage} alt="" />
                                    </div>
                                </div>
                                <div className="revenue-card card" onMouseEnter={()=>handleCardHover("FFF6E3")}>
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Revenue
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#FFBE23"
                                                highlightColor="#ed991d"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                    </div>
                                    <div className="revenue-card-pic-div">
                                        <img className="revenue-card-image" src={goldPlateImage} alt="" />
                                    </div>
                                </div>
                                <div className="subscribers-card card" onMouseEnter={()=>handleCardHover("FFE3F2")}>
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Subscribers
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#FB487E"
                                                highlightColor="#ed2662"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                    </div>
                                    <div className="subscribers-card-pic-div">
                                        <img className="subscribers-card-image" src={heartImage} alt="" />
                                    </div>
                                </div>
                                <div className="meetings-card card" onMouseEnter={()=>handleCardHover("FFE3E5")}>
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Meetings
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            <Skeleton width={50} height={40}
                                                className="mb-2"
                                                baseColor="#FB4848"
                                                highlightColor="#dd4b4b"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                    </div>
                                    <div className="meetings-card-pic-div">
                                        <img className="meetings-card-image" src={calendarHalfImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="container dashboard-container">
                            <div className="affiliate-div">
                                <div>
                                    <h2 className="m-0">Share Zaap. Get Paid.</h2>
                                    <p className="m-0">Refer creators and earn 25% profit share every month.</p>
                                </div>
                                <div>
                                    <a href="https://www.zaap.ai/learn/how-to-become-a-zaap-affiliate" target="_blank" className="btn-grey settings-button-primary me-2">
                                        FAQ’s 
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                            <path d="M1 7L7 1M7 1H3M7 1V5" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        </span>
                                    </a>
                                    <button onClick={()=>redirctToSettings('affiliate-program')} className="blue-shadow-button">
                                        Get Unique Link
                                    </button>
                                </div>
                            </div>
                            <div className="dashboard-analytics d-flex">
                                <div className="date-btns-selector">
                                    <div className={`date-filter-button me-2 active`} name="last_7_days">Last 7 Days</div>
                                    <div className={`date-filter-button me-2`}  name="last_14_days">Last 14 Days</div>
                                    <div className={`date-filter-button`}  name="last_30_days">Last 30 Days</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                        <div className="analytics-card">
                                            <div className="card-name">
                                                    Unique Visitors
                                            </div>

                                            <div className="analytics-details d-flex py-2">
                                                <div class="analytics-detail analytics-card-blue">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>
                                                        <span class={`analytics-badge analytics-badge-success d-flex align-items-center justify-content-center`}>
                                                            <img src={analyticsUp} width="7px" alt="" />
                                                            <span class="ms-1"></span>
                                                        </span>
                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Last 7 days</h5>
                                                </div>
                                                <div class="analytics-detail analytics-card-purple">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>

                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Previous period</h5>
                                                </div>
                                            </div>
                                            <div className="card-analytics-container">
                                                <Line id="analytics-main-chart"
                                                    data={dataUnique} options={originalOptionsForDashboardGraph}
                                                    width={"100%"} height={60} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                        <div className="analytics-card">
                                            <div className="card-name">
                                                Impressions
                                            </div>

                                            <div className="analytics-details d-flex py-2">
                                                <div class="analytics-detail analytics-card-blue">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>
                                                        <span class={`analytics-badge analytics-badge-success d-flex align-items-center justify-content-center`}>
                                                            <img src={analyticsUp} width="7px" alt="" />
                                                            <span class="ms-1"></span>
                                                        </span>
                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Last 7 days</h5>
                                                </div>
                                                <div class="analytics-detail analytics-card-purple">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>

                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Previous period</h5>
                                                </div>
                                            </div>
                                            <div className="card-analytics-container">
                                                <Line id="analytics-main-chart"
                                                    data={dataUnique} options={originalOptionsForDashboardGraph}
                                                    width={"100%"} height={60} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                        <div className="analytics-card">
                                            <div className="card-name">

                                                Meetings
                                            </div>

                                            <div className="analytics-details d-flex py-2">
                                                <div class="analytics-detail analytics-card-blue">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>
                                                        <span class={`analytics-badge analytics-badge-success d-flex align-items-center justify-content-center`}>
                                                            <img src={analyticsUp} width="7px" alt="" />
                                                            <span class="ms-1"></span>
                                                        </span>
                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Last 7 days</h5>
                                                </div>
                                                <div class="analytics-detail analytics-card-purple">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>

                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Previous period</h5>
                                                </div>
                                            </div>
                                            <div className="card-analytics-container">
                                                <Line id="analytics-main-chart"
                                                    data={dataUnique} options={originalOptionsForDashboardGraph}
                                                    width={"100%"} height={60} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                        <div className="analytics-card">
                                            <div className="card-name">

                                            Sales

                                            </div>

                                            <div className="analytics-details d-flex py-2">
                                                <div class="analytics-detail analytics-card-blue">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>
                                                        <span class={`analytics-badge analytics-badge-success d-flex align-items-center justify-content-center`}>
                                                            <img src={analyticsUp} width="7px" alt="" />
                                                            <span class="ms-1"></span>
                                                        </span>
                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Last 7 days</h5>
                                                </div>
                                                <div class="analytics-detail analytics-card-purple">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>

                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Previous period</h5>
                                                </div>
                                            </div>
                                            <div className="card-analytics-container">
                                                <Line id="analytics-main-chart"
                                                    data={dataUnique} options={originalOptionsForDashboardGraph}
                                                    width={"100%"} height={60} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                        <div className="analytics-card">
                                            <div className="card-name">

                                            Revenue

                                            </div>

                                            <div className="analytics-details d-flex py-2">
                                                <div class="analytics-detail analytics-card-blue">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>
                                                        <span class={`analytics-badge analytics-badge-success d-flex align-items-center justify-content-center`}>
                                                            <img src={analyticsUp} width="7px" alt="" />
                                                            <span class="ms-1"></span>
                                                        </span>
                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Last 7 days</h5>
                                                </div>
                                                <div class="analytics-detail analytics-card-purple">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>

                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Previous period</h5>
                                                </div>
                                            </div>
                                            <div className="card-analytics-container">
                                                <Line id="analytics-main-chart"
                                                    data={dataUnique} options={originalOptionsForDashboardGraph}
                                                    width={"100%"} height={60} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                        <div className="analytics-card">
                                            <div className="card-name">

                                            Subscribers

                                            </div>

                                            <div className="analytics-details d-flex py-2">
                                                <div class="analytics-detail analytics-card-blue">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>
                                                        <span class={`analytics-badge analytics-badge-success d-flex align-items-center justify-content-center`}>
                                                            <img src={analyticsUp} width="7px" alt="" />
                                                            <span class="ms-1"></span>
                                                        </span>
                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Last 7 days</h5>
                                                </div>
                                                <div class="analytics-detail analytics-card-purple">
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <h1 class="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>

                                                    </div>
                                                    <h5 class="mb-0 font-weight-500">Previous period</h5>
                                                </div>
                                            </div>
                                            <div className="card-analytics-container">
                                                <Line id="analytics-main-chart"
                                                    data={dataUnique} options={originalOptionsForDashboardGraph}
                                                    width={"100%"} height={60} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                }
            </div>

            {/* {showModal  && <DashboardProjectsModal showModal={showModal} handleCloseModal={handleCloseModal} getProjects={getProjects} />} */}
            {/* {state?.welcomeModalOpen && <WelcomeModal show={true}/>} */}
        </div>
    )
}
export default NewDashboard