import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { audiencePosts as eventTracking} from "../services/postRequests/audience";
import { apiUrl } from "../utils/url";
import Loader, { hideLoader, showLoader } from "../components/loader";

const Logout = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector(state => state.Login);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("name", "Sign Out");

        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        eventTracking(api, hideLoader, setOptions).then(()=>{
            if (state?.user) {
                setTimeout(() => {
                    dispatch({ type: "LOGOUT" });
                    localStorage.clear()
                    history.push("/login")
                }, 1000);
            }
            else if(localStorage.getItem("user")){
                localStorage.clear()
                window.location.pathname = "/login";
            }
        });

    }, [])

    return (
        <div class="full-page-loader d-flex">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default Logout