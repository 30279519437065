import React, { useEffect, useState } from 'react'
import { TeammemberSection } from '../teammember/TeammemberSection'
import NewUpgradePage from '../upgrade/new-upgrade-page'
import NewPageHeader from '../new-page-header'
import Teammembers from '../../pages/team-members'
import ManageBillings from '../../pages/manage-billings'
import PageEditor from '../../pages/page-editor'
import NewSideBar from '../side-bar/new-sidebar'
import Accounts from '../../pages/accounts'
import Passwords from './passwords'
import Domains from './domain'
import PageHeader from '../page-header'
import PayoutPage from './payout-page'
import IntegrationsPage from './integrations-page'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
// import Domains from './domain'

const Settings = () => {
    const location = useLocation();
    const { fromHeaderPage } = location?.state || {};
    const [activePage, setActivePage] = useState(localStorage?.openIntegrationPage ? "integrations" : fromHeaderPage ? fromHeaderPage :'upgrade'); // Set 'delete' as default active page
    const [sidebarOpen, setSideBarOpen] = useState(true);
    const [from, setfrom] = useState("settings");
    const handleMenuClick = (page) => {
      setActivePage(page);
      if (from =="settingsMobile") {
          setSideBarOpen(false)
      }
    };
    let activeContent;
    if (activePage === 'upgrade') {
        activeContent = <NewUpgradePage/>;
    }else if (activePage === 'team') {
        activeContent = <Teammembers setActivePage={setActivePage}/>;
    }else if (activePage === 'page') {
        activeContent = <PageEditor setActivePage={setActivePage}/>;
    }else if (activePage === 'payouts') {
        activeContent = <PayoutPage setActivePage={setActivePage}/>;
    }else if (activePage === 'domains') {
        activeContent = <Domains setActivePage={setActivePage}/>;
    }else if (activePage === 'integrations') {
        if (localStorage?.openIntegrationPage) {
            localStorage.removeItem("openIntegrationPage");
        }
        activeContent = <IntegrationsPage setActivePage={setActivePage}/>;
    }
    function checkScreenSize() {
        if (window.innerWidth <= 768) {
            setfrom("settingsMobile")
        }else{
            setfrom("settings")
        }
    }
    useEffect(()=>{
            window.addEventListener('resize', checkScreenSize);
            
            window.addEventListener('load', checkScreenSize);    
    },[])
    useEffect(()=>{
        checkScreenSize();
    },[location.pathname])
  return (
    <div id ="wrapper">
        <PageHeader from={from} activePage={activePage} sidebarOpen={sidebarOpen} setSideBarOpen={setSideBarOpen}/>
        <div className="community-main align-items-start">
            <NewSideBar from={from} sidebarOpen={sidebarOpen} setSideBarOpen={setSideBarOpen} onMenuClick={handleMenuClick} activePage={activePage} />
            {activeContent}
            {/* <NewUpgradePage/> */}
        </div>
    </div>
  )
}

export default Settings
