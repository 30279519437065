
import style from "../assets/css/project.css";
import copyComponent from "../assets/img/copy-clipboard.svg";
import plusIcon from "../assets/img/thin-plus-icon.svg";
import gearBlackIcon from "../assets/img/gear.svg";
import dragIcon from "../assets/img/page/drag-icon.svg";
import folderIcon from "../assets/img/page/blue-folder-icon.svg";
import contactIcon from "../assets/img/page/new-contact-icon.svg";
import chevronUpIcon from "../assets/img/page/chevron-up.svg";
import darkChevronUpIcon from "../assets/img/page/dark-cheveron-up.svg";
import gearPageIcon from "../assets/img/page/gear.svg";
import trashIcon from "../assets/img/page/trash.svg";
import plusPageIcon from "../assets/img/page/plus.svg";
import twitterIcon from "../assets/img/page/twitter.svg";
import visibleIcon from "../assets/img/page/mini-graph-icon.svg";
import openedEyeIcon from "../assets/img/page/opened-eye-icon.svg";
import closedEyeIcon from "../assets/img/page/closed-eye-icon.svg";
import youtubeIcon from "../assets/img/modals/youtube-icon.svg";
import tiktokIcon from "../assets/img/page/new-tiktok-icon.svg";
import emailIcon from "../assets/img/page/email-widget-icon.svg";
import textBlockIcon from "../assets/img/page/new-text-block-icon.svg";
import productBlockIcon from "../assets/img/page/product-block-icon.svg";
import returnIcon from "../assets/img/modals/return.svg"
import duplicateIcon from "../assets/img/duplicate-Icon.svg";
import embed_svg from "../assets/img/page/new-embed-icon.svg"
import MeetingBlockIcon from "../assets/img/calendar.svg"
import instagramIcon from "../assets/img/instagram.svg";
import postIcon from "../assets/img/post-block.svg";
import gradientEye from "../assets/img/gradient-eye.svg";

import linkIcon from "../assets/img/page/link-block-icon.svg";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as $ from "jquery"
import { useHistory, useLocation } from "react-router-dom";
import CustomizePage from "../components/page-editor/customize-page";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import PageHeader from "../components/page-header";
import { hideLoader, showLoader } from "../components/loader";
import { useDispatch, useSelector } from "react-redux"
import CreateLinkModal from "../components/page-editor/block-editor/sub-modals/createLinkModal";
import { toast } from "react-toastify";
import getProjects from "../services/getProjects";
import { apiUrl, pageUrl, protocol } from "../utils/url";
import CreateEmailWidgetModal from "../components/page-editor/block-editor/sub-modals/createEmailWidgetModal";
import CreateFolderModal from "../components/page-editor/block-editor/sub-modals/createFolderModal";
import CreateYoutubeWidgetModal from "../components/page-editor/block-editor/sub-modals/createYoutubeWidgetModal";
import CreateTiktokWidgetModal from "../components/page-editor/block-editor/sub-modals/createTiktokWidgetModal";
import CreateTwitterWidgetModal from "../components/page-editor/block-editor/sub-modals/createTwitterWidgetModal";
import CreateTextBlockModal from "../components/page-editor/block-editor/sub-modals/createTextBlockModal";
import EditLinkModal from "../components/page-editor/block-editor/sub-modals/editLinkModal";
import CroppieModal from "../components/croppie-modal";
import Croppie from "croppie";
import "croppie/croppie.css"
import EditLinkProfilePicUploadModal from "../components/picture-upload-modal";
import SeoUploadModal from "../components/seo-upload-modal";
import ProfilePicUploadModal from "../components/customize-picture-upload-modal";
import CongratsModal from "../components/page-editor/congrats-modal";
import UploadImageBanner from "../components/page-editor/block-editor/sub-modals/uploadImageBanner";
import CreateProductWidgetModal from "../components/page-editor/block-editor/sub-modals/createProductWidgetModal";
import { ReactSortable } from "react-sortablejs";
import { Button, Modal } from "react-bootstrap";
import ConfirmationLinkModal from "../components/page-editor/block-editor/sub-modals/confirmation-link-modal";
import CreateEmbedBlockModal from "../components/page-editor/block-editor/sub-modals/createEmbedBlockModal";
import CustomDomainDotModal from "../components/page-editor/block-editor/sub-modals/customDomainDotModal";
import CustomDomainConnectedModal from "../components/page-editor/block-editor/sub-modals/customDomainConnectedModal";
import CreateContactModal from "../components/page-editor/block-editor/sub-modals/createContactModal.";
import CreateMeetingProductWidgetModal from "../components/page-editor/block-editor/sub-modals/createMeetingProductWidgetModal";
import NewSideBar from "../components/side-bar/new-sidebar";
import CreateInstagramWidgetModal from "../components/page-editor/block-editor/sub-modals/createInstagramWidgetModal";
import BlockEditor from "../components/page-editor/block-editor";
import CreateZaapPostsModal from "../components/page-editor/block-editor/sub-modals/createZaapPostsModal";
import CreateLeadMagnetWidgetModal from "../components/page-editor/block-editor/sub-modals/createLeadMagnetWidgetModal";
import leadMagnetIcon from "../assets/img/lead-magnet.svg"

export const mainPath = "page-editor";
const modalsConstant = { show: false, id: null };

let script_tag = `<script src="https://sortablejs.github.io/Sortable/Sortable.js"></script>`

const folderSortable = (setIframeURL) => {

    const el = document.querySelector('.editor-links-main')
    let folders_sortable = Sortable?.create(el, {
        animation: 150,
        handle: '#drag',
        // filter: '.ignore-class',
        draggable: '.folder',
        onUpdate: (event) => {
            let folders = [];
            $('.editor-links-main> .folder').each((i, elm) => {
                let folder = {
                    folder_id: elm.dataset.folder,
                    order_no: i
                };
                folders.push(folder);
            })

            if (folders.length > 0)
                $.ajax({
                    type: 'POST',
                    url: `${apiUrl}/project/sort`,
                    data: {
                        request_type: 'order',
                        folders,
                        project: localStorage.slug,
                    },
                    headers: {
                        'Authorization': `Bearer ${localStorage?.getItem("token")}`
                    },
                    dataType: 'json',
                    success: (response) => {
                        setTimeout(() => {
                            setIframeURL(protocol + '' + pageUrl + '/' + localStorage.slug + '?iframe=1&v=' + Math.random(100));
                        }, 500);
                    }
                });
            /* Refresh iframe */
        }
    });
}

let croppie_image = { url: "", id: null };
const PageEditor = ({ iframeURL, setIframeURL, setconfirmationLinkModal, confirmationLinkModal }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setloading] = useState(true);
    const [iframeIndex, setIframeIndex] = useState(1);
    const [linkModal, setlinkModal] = useState({
        show: false,
        parent_id: null
    });
    const [blockModal, setBlockModal] = useState(false);
    const { state } = useLocation();

    const [croppie, setCroppie] = useState(null);
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const pro = useSelector(state => state?.proReducer);
    const blockList = useSelector(state => state?.blockReducer)
    const [error, setError] = useState([]);
    const seoCroppieElement = useSelector(state => state?.seoCroppieElement)
    const [emailWidgetModal, setemailWidgetModal] = useState(modalsConstant);
    const [folderModal, setfolderModal] = useState(modalsConstant);
    const [productModal, setproductModal] = useState(modalsConstant);
    const [calendarProductModal, setcalendarProductModal] = useState(modalsConstant);
    const [leadMagnetModal, setLeadMagnetModal] = useState(modalsConstant);
    const [youtubeModal, setyoutubeModal] = useState(modalsConstant);
    const [tiktokModal, settiktokModal] = useState(modalsConstant);
    const [instagramModal, setinstagramModal] = useState(modalsConstant);
    const [twitterModal, settwitterModal] = useState(modalsConstant);
    const [zaapPostsModal, setzaapPostsModal] = useState(modalsConstant);
    const [contactModal, setcontactModal] = useState(modalsConstant);
    const [textBlockModal, settextBlockModal] = useState(modalsConstant);
    const [embedBlockModal, setembedBlockModal] = useState(modalsConstant);
    const [showCroppieModal, setshowCroppieModal] = useState(false);
    const showprofilePicModal = useSelector(state => state.profilePicUploadReducer);
    const showSeoPictureModal = useSelector(state => state.seoPicUploadReducer);
    const showEditProfilePicModal = useSelector(state => state.editlinkProfilePicUploadReducer);
    const uploadImageReducer = useSelector(state => state.uploadImageReducer);
    const editLinkModal = useSelector(state => state.editLinkModal)
    const { customizeSeoReducer, bannerModal } = useSelector(state => state);
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null });
    const [isConfirmMessage, setisConfirmMessage] = useState(false)
    const showCustomDomainDotsModal = useSelector(state => state?.domainDotModalShowReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const collapsible = (e) => {
        e.stopPropagation()

        // console.log(e.target);
        if (e.target.type == "submit") {
            $(e.target).find('img').toggleClass('rotate180');
            $(e.target).parent().parent().parent().next().slideToggle(400);
        }
        else {
            $(e.target).toggleClass('rotate180');
            $(e.target).parent().parent().parent().parent().next().slideToggle(400);
        }
    };
    const handleHide = () => {
        setlinkModal(modalsConstant)
        setemailWidgetModal(modalsConstant)
        setfolderModal(modalsConstant)
        setproductModal(modalsConstant)
        setyoutubeModal(modalsConstant)
        settiktokModal(modalsConstant)
        settwitterModal(modalsConstant)
        setzaapPostsModal(modalsConstant)
        setembedBlockModal(modalsConstant)
        setcontactModal(modalsConstant)
        setLeadMagnetModal(modalsConstant)
        dispatch({ type: "RESET_EDIT_LINK_MODAL" });
        settextBlockModal(modalsConstant);
        history.push(basePath + "/page-editor")
        // CROPPIE
        setshowCroppieModal(false);
        setCroppie(null)
        croppie_image = { url: "", id: null };

        dispatch({ type: "RESET_PROFILE_PIC_MODAL" });
        dispatch({ type: "RESET_EDITLINK_PROFILE_PIC_MODAL" });
        // hideLoader()
    }
    const callGetProject = (toastShow) => {
        getProjects(slug).then((response) => {
            // console.log("response", response);
            dispatch({ type: "SET_BLOCK_SETTING", payload: response?.blocks });
            // dispatch({ type: "SET_NEW_USER", payload: response?.data.projects[0]?.new_user });
            // if (toastShow) toast.success(response?.status_message)
            setTimeout(() => {
                setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
                setIframeIndex(iframeIndex + 1);
                hideLoader();
            }, 1000);
        }).catch((err) => {
            hideLoader();
        });
    }
    const handleSubmit = (e, values, blockName, isUpdate, blockTitle) => {

        // console.log("Handle Submit")
        let end_point = isUpdate;
        showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const errorList = []
        const formdata = new FormData();
        let formValues = {};
        if (blockTitle == "twitter") {
            if (values?.connect == 1) {
                formValues = {
                    block: blockName,
                    project: slug,
                    connect: 1,
                    end_point: end_point
                }
                const valueToStore = { ...values, ...formValues, blockName: blockTitle, isUpdate }
                localStorage.setItem("twitter_values", JSON.stringify(valueToStore))
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                endpoint = values?.isUpdate;
                formValues = values;
                delete formValues["connect"];
                delete formValues["isUpdate"];
                localStorage.removeItem("twitter_values");
            }
        }
        else if (blockTitle == "tiktok") {
            if (values?.connect == 1) {
                formValues = {
                    block: blockName,
                    project: slug,
                    connect: 1,
                    end_point: end_point
                }
                const valueToStore = { ...values, ...formValues, blockName: blockTitle, isUpdate }
                localStorage.setItem("tiktok_values", JSON.stringify(valueToStore))
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                endpoint = values?.isUpdate;
                formValues = values;
                delete formValues["connect"];
                delete formValues["isUpdate"];
                // localStorage.removeItem("tiktok_values");
            }
        } else if (blockTitle == "instagram") {
            if (values?.connect == 1) {
                formValues = {
                    block: blockName,
                    project: slug,
                    connect: 1,
                    end_point: end_point
                }
                const valueToStore = { ...values, ...formValues, blockName: blockTitle, isUpdate }
                localStorage.setItem("instagram_values", JSON.stringify(valueToStore))
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                endpoint = values?.isUpdate;
                formValues = values;
                delete formValues["connect"];
                delete formValues["isUpdate"];
            }
        }
        else {
            formValues = {
                block: blockName,
                project: slug,
                ...values
            }
        };

        delete formValues?.image_url;
        delete formValues?.show;
        if (!formValues?.imageData) formValues?.imageData;

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {

                // console.log("Object", key);
                if (key == "imageData") {
                    if (formValues[key]) formValues[key] = new File([formValues[key]], "cover.png")

                }
                formdata.append(key, formValues[key]);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        // console.log("API URL", apiUrl)
        fetch(apiUrl + `/project/block/${end_point ? "update" : "create"}`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (blockTitle !== "twitter" && blockTitle !== "tiktok" && blockTitle !== "instagram") {

                    // console.log("Title")
                    if (response?.status_code == 200) {
                        toast.success(response?.status_message);
                        callGetProject()
                        if (blockName == "link") {
                            setlinkModal({
                                show: false,
                                parent_id: null
                            })
                            setTimeout(() => {
                                dispatch({
                                    type: "SET_EDIT_LINK_MODAL", payload: {
                                        show: true,
                                        id: response?.data?.id,
                                        link_title: response?.data?.name,
                                        destination_url: JSON?.parse(response?.data?.details?.meta)?.destination_url,
                                        link_description: JSON?.parse(response?.data?.details?.meta)?.link_description,
                                        open_in_same_tab: JSON?.parse(response?.data?.details?.meta)?.open_in_same_tab,
                                        button_text: JSON?.parse(response?.data?.details?.meta)?.button_text,
                                        link_image: JSON?.parse(response?.data?.details?.meta)?.link_image,
                                    }
                                })
                            }, 200);
                        }
                        else {

                            setTimeout(() => {
                                hideLoader()
                                handleHide()
                            }, 500);
                        }
                    }
                    else {
                        if (formValues?.open_in_same_tab) {
                            setError(Object.values(response?.errors))
                            response?.errors?.link_description?.map((val) => errorList?.push(val));
                        }
                        else {
                            response?.errors?.link_description?.map((val) => errorList?.push(val));
                            response?.errors?.link_title?.map((val) => errorList?.push(val));
                            response?.errors?.destination_url?.map((val) => errorList?.push(val));
                            setError(errorList)
                        }

                        // console.log("Error List", errorList)
                        if (errorList.length !== 0) {

                            // console.log("Error List", errorList.length)
                            errorList?.map((err) => {
                                return (
                                    toast.error(err)
                                );
                            });
                        }
                        else {
                            toast.error(response?.status_message)
                        }
                        hideLoader()
                    }
                }
                else {
                    if (response?.status_code == 200) {
                        toast.success(response?.status_message);
                        if (values?.connect == 1) {
                            setTimeout(() => {
                                localStorage.setItem("last_activity", location.pathname);
                                location.href = response?.data?.oauth_url
                                // const popup_window = window.open(
                                //     // `http://localhost:3000/tiktok-calback?code=n3gNWsWkYP_3f3YpazT3g45FaDfyf0p_pFuZnwripvXMZgnMLS4ryJHUr0XhEb7dtrZcC514QV03KjBHBBzsyAuWct0YAvZtbrhJthhdxT0%2A3%215871&scopes=user.info.basic%2Cvideo.list&state=1666709441423`
                                //     response?.data?.oauth_url
                                //     ,
                                //     'targetWindow',
                                //     `toolbar=no,
                                //    location=no,
                                //    status=no,
                                //    menubar=no,
                                //    scrollbars=yes,
                                //    resizable=yes,
                                //    width=SomeSize,
                                //    height=SomeSize`)
                                // popup_window.addEventListener('close', (() => {
                                //     
                                // console.log("popup_window");
                                // }));
                            }, 1000);
                        }
                        else {
                            callGetProject()
                            setTimeout(() => {
                                hideLoader()
                                handleHide()
                            }, 1000);
                        }
                    }
                    else {
                        toast.error(response?.status_message)
                        hideLoader()
                    }
                }
            }).catch((err) => {
                hideLoader()
            })

    }




    const showModal = (paramsName) => {
        // setShowParent(true)
        history.push({
            state: { show: true }
        })
        setTimeout(() => {
            history.push({
                pathname: `${basePath}/${mainPath}/${paramsName}`,
                state: { show: true, from: 'folder_block' }
            })
        }, 200);
    }
    const onDelete = (id) => setconfirmationModalShow({ show: true, text: "Are you sure you want to delete this block?", id: id, callBackTo: "delete" })
    const handleStatus = (e) => {
        let status = e.target.id.includes("on") ? 1 : 0;
        // showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        formdata.append("block", e.target.dataset.id)
        formdata.append("project", slug)
        formdata.append("status", status)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formdata
        };
        fetch(apiUrl + "/project/block/status", requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if (status == 0) toast.success("Block Disabled Successfully");
                    else toast.success("Block Enabled Successfully");
                    callGetProject();
                }
            }).catch((err) => { })
    }

    const removeModal = () => {
        showLoader()
        window.history.pushState(mainPath, 'Title', `${basePath}/${mainPath}`)
        hideLoader()
    }

    const editModal = (e, data) => {

        // console.log("data", data);

        // console.log("type", data.type);
        if (data?.type == "email_widget")
            setemailWidgetModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)?.description
            })
        else if (data?.type == "folder") {
            setfolderModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
            })
        }
        else if (data?.type == "product") {
            setproductModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)?.product
            })
        }
        else if (data?.type == "meeting") {
            setcalendarProductModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)?.product
            })
        }
        else if (data?.type == "lead_magnet") {
            setLeadMagnetModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        }
        else if (data?.type == "youtube") {
            setyoutubeModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        }
        else if (data?.type == "tiktok") {
            settiktokModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        }
        else if (data?.type == "instagram") {
            setinstagramModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        }
        else if (data?.type == "twitter") {
            settwitterModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        }
        else if (data?.type == "link") {
            let description = JSON?.parse(data?.details?.meta)?.link_description;

            if (description) {
                try {
                    description = decodeURIComponent(description);
                } catch (error) {
                    console.error("Error decoding description:", error);
                }
            }
            dispatch({
                type: "SET_EDIT_LINK_MODAL", payload: {
                    show: true,
                    id: e.target.dataset?.id,
                    link_title: data?.name,
                    destination_url: JSON?.parse(data?.details?.meta)?.destination_url,
                    link_description: description,
                    open_in_same_tab: JSON?.parse(data?.details?.meta)?.open_in_same_tab,
                    button_text: JSON?.parse(data?.details?.meta)?.button_text,
                    imageData: JSON?.parse(data?.details?.meta)?.link_image,
                    link_image: JSON?.parse(data?.details?.meta)?.link_image,
                    image_url: JSON?.parse(data?.details?.meta)?.link_image,

                }
            })
        }
        else if (data?.type == "text_block") {
            settextBlockModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        }
        else if (data?.type == "embed") {
            setembedBlockModal({
                ...confirmationLinkModal,
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })

        }
        else if (data?.type == "contact") {
            setcontactModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        }
        else if (data?.type == "zaap_posts") {
            setzaapPostsModal({
                show: true,
                id: e.target.dataset?.id,
                name: data?.name,
                details: JSON?.parse(data?.details?.meta)
            })
        }
    }

    const duplicateBlock = (id) => {
        setconfirmationModalShow({
            show: true, text: "Are you sure you want to duplicate this block?", id: id, callBackTo: "duplicate"
        })
    }

    //#region CROPPIE FOR SEO IMAGE

    function new_thumbnail_upload_function(input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            //   alert(input.files[0].size);
            if (input.files[0].size >= 5242880) {
                $('#image_file_input').val('');
                toast.error('File Size Should not exceed 5MBs');
                return;
            }
            var val = input.files[0].name;
            // alert(val);
            var valid_extensions = ['png', 'jpg', 'jpeg'];
            var extension = val.substring(val.lastIndexOf('.') + 1, val.length) || val;
            if (!valid_extensions.includes(extension)) {
                $('#image_file_input').val('');
                toast.error('Invalid Image File. Should be png, jpg or jpeg');
                return;
            }
            setshowCroppieModal(true)
            reader.onload = event => {
                croppie_image = {
                    url: event.target.result,
                }
                const el = document.getElementById("image_demo2")
                try {
                    if (el) {
                        let croppieInstance = new Croppie(el, {
                            enableExif: true,
                            viewport: {
                                width: 250,
                                height: 250,
                                type: 'circle' //circle
                            },
                            boundary: {
                                width: 300,
                                height: 300
                            }
                        });
                        croppieInstance.bind({
                            url: event.target.result,
                        }).then(function () {

                            // console.log('jQuery bind complete');
                        });
                        setCroppie(croppieInstance)
                    }
                } catch { }

            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    const croppieClose = () => {
        showLoader()
        setshowCroppieModal(false);
        setCroppie(null)
        croppie_image = { url: "", id: null };

        dispatch({ type: "RESET_PROFILE_PIC_MODAL" });
        dispatch({ type: "RESET_EDITLINK_PROFILE_PIC_MODAL" });

        setTimeout(() => {
            history.goBack();
            hideLoader()
        }, 300);

    }

    const croppedImage = () => {
        if (croppie !== null) {
            croppie.result({
                type: "canvas",
                size: "original",
                format: "png",
                quality: 1,
                type: "blob"
            })
                .then((response) => {
                    dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...customizeSeoReducer, ["imageData"]: response, "image": croppie?.data?.url } });
                    setshowCroppieModal(false);
                    setTimeout(() => {
                        history.push(basePath + "/page-editor/customizePageModal/customizePageSEO");
                    }, 300);

                })
        }
    };

    function generate_image_preview(input) {

        if (input.files && input.files[0]) {
            let reader = new FileReader();
            //   alert(input.files[0].size);
            if (input.files[0].size >= 5242880) {
                toast.error('File Size Should not exceed 5MBs');
                dispatch({ type: "RESET_SEO_CROPPIE_ELEMENT" })
                return;
            }
            var val = input.files[0].name;
            // alert(val);
            var valid_extensions = ['png', 'jpg', 'jpeg'];
            var extension = val.substring(val.lastIndexOf('.') + 1, val.length) || val;
            if (!valid_extensions.includes(extension)) {
                toast.error('Invalid Image File. Should be png, jpg or jpeg');
                dispatch({ type: "RESET_SEO_CROPPIE_ELEMENT" })
                return;
            }
            $('.ppModal').click();
            setshowCroppieModal(true)
            reader.onload = event => {
                croppie_image = {
                    url: event.target.result,
                }
                const el = document.getElementById("image_demo2")
                try {
                    if (el) {
                        var croppieInstance = new Croppie(el, {
                            enableExif: true,
                            viewport: {
                                width: 250,
                                height: 250,
                                type: 'square' //circle
                            },
                            boundary: {
                                width: 300,
                                height: 300
                            }
                        });
                        croppieInstance.bind({
                            url: event.target.result,
                        }).then(function () {

                            // console.log('jQuery bind complete');
                            setCroppie(croppieInstance)
                        });
                    }
                } catch { }

            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    //#endregion


    useEffect(() => {
        if (seoCroppieElement?.files?.length > 0) {
            showLoader();
            setTimeout(() => {
                // new_thumbnail_upload_function(seoCroppieElement);
                setTimeout(() => {
                    history.push(basePath + "/page-editor")
                    hideLoader();
                }, 1000);
            }, 500);
            setTimeout(() => {
                generate_image_preview(seoCroppieElement);
            }, [500])
        }

    }, [seoCroppieElement]);

    // try {
    //     document?.querySelector(".dropzone4").addEventListener("change", (e) => {
    //         showLoader();
    //         setTimeout(() => {
    //             // new_thumbnail_upload_function(document?.querySelector(".dropzone4"));
    //             setTimeout(() => {
    //                 history.push(basePath + "/page-editor")
    //                 hideLoader();
    //             }, 1000);
    //         }, 500);
    //         setTimeout(() => {
    //             generate_image_preview(e.target);
    //         }, [500])
    //     })
    // } catch { }

    useEffect(() => {
        setTimeout(() => {
            setloading(false);
            setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
        }, 1500);
    }, [blockList]);

    var nestedSortables = [].slice.call(document.querySelectorAll('.nested-sortable'));
    const folderLinkSortable = () => {
        const elements = document?.querySelectorAll('.editor-link-collapsible');
        
        if (elements && elements.length > 0) {
            elements.forEach((el) => {
                if (el) {
                    Sortable.create(el, {
                        animation: 150,
                        handle: '#drag',
                        onUpdate: (event) => {
                            let folders = [];
                            document.querySelectorAll('.editor-link-collapsible > .nested-block').forEach((elm, i) => {
                                let folder = {
                                    link_id: elm.dataset.blockid,
                                    order_no: i
                                };
                                folders.push(folder);
                            });
                            if (folders.length > 0) {
                                sendDragRequest(folders);
                            }
                        },
                    });
                }
            });
        }
    };
    useEffect(() => {
        dispatch({ type: "RESET_UPLOAD_IMAGE" })
        $("body").append(script_tag)

    }, [])
    useEffect(() => {
        nestedSortables = [].slice.call(document.querySelectorAll('.nested-sortable'));
         if (typeof Sortable !== 'undefined') {
        folderSortable(setIframeURL)
        folderLinkSortable();
        if (nestedSortables?.length > 1) {
            nestedLinksSortables(nestedSortables);
        }
    }
    }, [blockList,nestedSortables]);


    const copyingText = (text) => navigator.clipboard.writeText(text).then((r => toast.success("Successfully Copied")));

    const draggableIcon = () => <img src={dragIcon} id="drag" className="me-3 cursor-grab" alt="" />

    const sendDragRequest = (links) => {
        // let links = []
        // for (let index = 0; index < list.length; index++) {
        //     // const element = list[index];
        //     let link = {
        //         link_id: list[index].id,
        //         order_no: index
        //     };
        //     links.push(link)

        // }
        $.ajax({
            type: 'POST',
            url: `${apiUrl}/project/sort`,
            data: {
                request_type: 'order',
                links,
                project: localStorage.slug,
            },
            headers: {
                'Authorization': `Bearer ${localStorage?.getItem("token")}`
            },
            dataType: 'json',
            success: (response) => {
                // 
                // console.log("response", response)
                setTimeout(() => {
                    setIframeURL(protocol + '' + pageUrl + '/' + localStorage.slug + '?iframe=1&v=' + Math.random(100));
                }, 500);
            }
        });
    }
    const nestedLinksSortables = (nestedSortables) => {
        for (let i = 0; i < nestedSortables.length; i++) {
            (function (index) {
                new Sortable(nestedSortables[index], {
                    handle: '#drag',
                    group: {
                        name: 'nested',
                        put: true,
                        pull: true,
                    },
                    animation: 150,
                    fallbackOnBody: true,
                    swapThreshold: 0.65,
                    onAdd: (evt) => {
                        let dragIn = evt?.to?.classList?.contains('nested-folder-blocks');
                        let mainDiv;
                        if (dragIn) {
                            // mainDiv = document.querySelectorAll('.editor-link-collapsible')[0];
                            mainDiv = evt?.to;
                        }else{
                            mainDiv = evt?.from;
                        }
                        let hasNotChildDiv = mainDiv?.children?.length === 0 ? true : false;
                        let isLink = (!dragIn)|| (evt?.item?.dataset?.type == mainDiv?.dataset?.foldertype && !hasNotChildDiv) || ((mainDiv.dataset?.foldertype == 'any' || hasNotChildDiv) && (evt?.item?.dataset?.type == 'link' || evt?.item?.dataset?.type == 'product' || evt?.item?.dataset?.type == 'meeting'));
                        if (!isLink) {
                            evt?.from?.insertBefore(evt?.item, evt?.from?.childNodes[evt?.oldIndex]);
                            return;
                        } else {
                            let folders = [];
                            let nestedBlocks = mainDiv.querySelectorAll('.nested-block');
                            let folder_id = mainDiv.dataset?.folderid;
                            
                            nestedBlocks?.forEach(nestedBlock => {
                                const dataId = nestedBlock?.dataset?.blockid ?? null;
                                folders?.push({ dataId });
                            });
                            $?.ajax({
                                type: 'POST',
                                url: `${apiUrl}/project/nested-sort`,
                                data: {
                                    folder_id: folder_id,
                                    folders: folders,
                                    project: localStorage?.slug,
                                },
                                headers: {
                                    'Authorization': `Bearer ${localStorage?.getItem("token")}`
                                },
                                dataType: 'json',
                                success: () => {
                                    isLink = false;
                                    folders = [];
                                    setIframeURL(protocol + '' + pageUrl + '/' + localStorage.slug + '?iframe=1&v=' + Math.random(100));
                                    console.log("Nested sorting successful");
                                }
                            });
                        }
                    },
                });
            })(i);
        }
    };
    const handleCloseConfirmation = () => {
        setconfirmationModalShow({ show: false, text: null, id: null })
        setisConfirmMessage(true);
    };
    const handleClose = () => {
        setBlockModal({ show: false, from: "folder_block" })
    };
    const confirmedDeleteBlock = (e) => {
        e.preventDefault();
        showLoader()
        if (confirmationModalShow?.id) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            var requestOptions = {
            };
            const formdata = new FormData();
            var api = `${apiUrl}/project/block/`
            if (confirmationModalShow?.callBackTo == "delete") {
                requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                }
                api = `${apiUrl}/project/block/delete/${confirmationModalShow?.id}`
            }
            else {
                api = `${apiUrl}/project/block/duplicate`

                formdata.append("project", slug)
                formdata.append("block", confirmationModalShow?.id)

                requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: 'follow',
                    body: formdata
                }
            }

            // setconfirmationModalShow({ show: false, text: null, id: null })
            fetch(api, requestOptions)
                .then(async (res) => {
                    const response = await res?.json();
                    if (response?.status_code == 200) {
                        if (confirmationModalShow?.callBackTo == "delete") {
                            // $(`[data-folder='${confirmationModalShow?.id}']`)?.addClass('d-none');
                            // $(`[data-folder='${confirmationModalShow?.id}']`)?.remove();
                            // setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
                            // setIframeIndex(iframeIndex + 1);
                            const updatedBlockList = blockList.filter(block => block.id !== confirmationModalShow?.id);
                            dispatch({ type: 'SET_BLOCK_SETTING', payload: updatedBlockList });
                            hideLoader()
                         
                        }else {
                            callGetProject(true);
                        }
                    }
                    else {
                        toast.error(response?.status_message)
                        hideLoader()
                    }
                    setconfirmationModalShow({ show: false, text: null, id: null })
                    setisConfirmMessage(false)
                })
                .catch(() => {
                    setconfirmationModalShow({ show: false, text: null, id: null })
                    setisConfirmMessage(false)
                    hideLoader()
                })
        }

    }

    const handleHideConnectedModal = (onDomain) => {
        dispatch({ type: "SET_DOMAIN_MODAL_SHOW", payload: { dotModal: false, connectedModal: false } });
        if (onDomain)
            setTimeout(() => {
                history.push(`${basePath}/page-editor/customizePageModal/customizePageDomains`)
            }, 200);
    }
    const openChildModal = (val) => {
        const hasLinkChild = val.child_links.some((child) => child.type === "link");
        if (hasLinkChild) {
            setlinkModal({
                show: !linkModal?.show, parent_id: val?.id
            })
        } else {
            const hasProductChild = val.child_links.some((child) => child.type === "product");
            if (hasProductChild) {
                setproductModal({
                    show: !productModal?.show,
                    parent_id: val?.id,
                    from: 'folder_block'
                })
            } else {
                const hasMeetingChild = val.child_links.some((child) => child.type === "meeting");
                if (hasMeetingChild) {
                    setcalendarProductModal({
                        show: !calendarProductModal?.show,
                        parent_id: val?.id,
                        from: 'folder_block'
                    })
                } else {
                    setBlockModal({
                        show: !blockModal?.show,
                        parent_id: val?.id,
                        from: 'folder_block'
                    })
                }
            }
        }
    }
    return (
        <>
            <div id="wrapper">
                <PageHeader />
                <div className="page-editor-main">
                    <NewSideBar />
                    <section className="page-editor-section">

                        <div className="editor-header d-flex align-items-center justify-content-start ">
                            <button className="btn btn-add-block me-3 newBlockModal"
                                // data-bs-toggle="modal" data-bs-target="#newBlockModal"
                                onClick={() => showModal("newBlockModal")}>
                                <img src={plusIcon} alt="" width={10} />
                                <span>Add block</span>
                            </button>
                            <button class="btn btn-customize-page customizePageModal"
                                onClick={() => showModal("customizePageModal")}>
                                <img src={gearBlackIcon} width={14} alt="" />
                                <span >Customize Page</span>
                            </button>
                        </div>
                        {loading ?
                            <div className="editor-links-main" >

                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />

                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />

                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />

                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />

                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />

                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />
                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />

                                <Skeleton height={80}
                                    className="mt-2"
                                    borderRadius="20px"
                                    highlightColor="#ffffff"
                                    duration={4}
                                />

                            </div>
                            :
                            <div key={blockList?.length} className="editor-links-main nested-sortable" id="editor-links-main ">
                                {blockList?.length > 0 && blockList?.map((val, index) => {
                                    if (val?.type == "folder")
                                        return (
                                            <div id="singleBlockDrag" className="folder" data-folder={val?.id}>
                                                <div className="editor-link editor-link-folder" id="folder-card">
                                                    <div className="d-flex align-items-center editor-link-first-half">
                                                        {draggableIcon()}
                                                        <img src={folderIcon} className="me-3" alt="" />
                                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                                            <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                        <div className="d-flex align-items-center">
                                                            <button className="btn btn-sm ignore-class link-action-button btn-folder-collapsible" onClick={collapsible}>
                                                                <img src={darkChevronUpIcon} width="14px" alt="" onClick={collapsible} />
                                                            </button>
                                                            <button className="btn btn-sm ignore-class link-action-button ms-2" onClick={(e) => editModal(e, val)} data-id={val?.id}>
                                                                <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                            </button>
                                                            <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                                <img src={trashIcon} width="14px" alt="" />
                                                            </button>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <button className="btn-add-link ignore-class btn btn-primary btn-sm btn-radius-12 py-2 ps-3 pe-3 d-flex align-items-center justify-content-center ms-2"
                                                                onClick={() => openChildModal(val)}>
                                                                <img src={plusIcon} alt="" width="12px" />
                                                                Add Block
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-folderType={val?.child_links && val?.child_links?.length > 0 ? val?.child_links[0]?.type : 'any'} data-folderId={val?.id} className="editor-link-collapsible nested-folder-blocks nested-sortable">
                                                    {/* <ReactSortable className="nested-folder-blocks " handle="#drag" list={val?.child_links} setList={(e, i) => {
                                                        let tempArr = [...blockList];
                                                        tempArr[index].child_links = e
                                                        dispatch({ type: "SET_BLOCK_SETTING", payload: tempArr });
                                                        sendDragRequest(e)
                                                    }}> */}

                                                    {val?.child_links?.length > 0 && val?.child_links?.map((child, indexChild) => {
                                                        if (child?.type == "link") {
                                                            let destination_url = null;
                                                            if (child?.details?.meta)
                                                                destination_url = JSON.parse(child?.details?.meta)?.destination_url

                                                            return (
                                                                <div data-blockId={child?.id} data-folder={child?.id} style={{ height: "70px" }} data-type="link" className={"nested-block links_sortables editor-link"} key={indexChild} id="single-link-card">
                                                                    <div className="d-flex align-items-center editor-link-first-half">
                                                                        {draggableIcon()}
                                                                        <img src={linkIcon} className="me-3" alt="" />
                                                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                                                            <h5 className="editor-link-headings mb-0">{child?.name}</h5>
                                                                            <h6 className="text-muted-light mt-1 mb-0">
                                                                                {destination_url ? (destination_url.length > 21 ? destination_url.slice(0, 21) + '...' : destination_url) : null}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center editor-link-second-half">
                                                                        <div className="d-flex align-items-center">
                                                                            <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                                                <input type="radio" id={"off" + child?.id} name={"enabled" + child?.id} onChange={handleStatus} checked={child?.is_enabled == 0 ? true : false} data-id={child?.id} value={false} />
                                                                                <label for={"off" + child?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${child?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                                                <input type="radio" id={"on" + child?.id} name={"enabled" + child?.id} onChange={handleStatus} checked={child?.is_enabled == 1 ? true : false} data-id={child?.id} value={true} />
                                                                                <label for={"on" + child?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                                                <span className={`toggle_switcher ${child?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center">
                                                                            <button className="btn btn-sm ignore-class link-action-button py-01 me-2 w-auto d-flex align-items-center jusitfy-content-center">
                                                                                <img src={visibleIcon} width={12} height={15} alt="" />
                                                                                <small>{child?.clicks}</small>
                                                                            </button>
                                                                            <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(child?.id)}>
                                                                                <img src={duplicateIcon} data-id={child?.id} width="14px" alt="" />
                                                                            </button>
                                                                            <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={child?.id} onClick={(e) => editModal(e, child)}>
                                                                                <img src={gearPageIcon} data-id={child?.id} onClick={(e) => editModal(e, child)} width="14px" alt="" />
                                                                            </button>
                                                                            <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={child?.id} onClick={() => onDelete(child?.id)}>
                                                                                <img src={trashIcon} width="14px" alt="" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        } else if (child?.type == "product") {
                                                            return (
                                                                <div data-blockId={child?.id} data-folder={child?.id} data-type="product" className={"nested-block links_sortables editor-link folder" + (child?.is_enabled == 1 ? "" : " disabled-link")} >
                                                                    <div className="d-flex align-items-center editor-link-first-half">
                                                                        {draggableIcon()}
                                                                        <img src={productBlockIcon} className="me-3" alt="" />
                                                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                                                            <h5 className="editor-link-headings mb-0">{child?.name}</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center editor-link-second-half">
                                                                        <div className="d-flex align-items-center">
                                                                            {/* <label className="my-0 ms-0 me-2 link-switch">
                                                                                    <input type="checkbox" className="" checked={val?.is_enabled == 1 ? true : false} onChange={handleStatus} data-id={val?.id} />
                                                                                    <span className="slider round"></span>
                                                                                </label> */}
                                                                            <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded">
                                                                                <input type="radio" id={"off" + child?.id} name={"enabled" + child?.id} onChange={handleStatus} checked={child?.is_enabled == 0 ? true : false} data-id={child?.id} value={false} />
                                                                                <label for={"off" + child?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img src={closedEyeIcon} alt="" srcset="" /></label>
                                                                                <input type="radio" id={"on" + child?.id} name={"enabled" + child?.id} onChange={handleStatus} checked={child?.is_enabled == 1 ? true : false} data-id={child?.id} value={true} />
                                                                                <label for={"on" + child?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                                            </div>
                                                                            <button className="btn btn-sm ignore-class link-action-button py-01 me-2 w-auto d-flex align-items-center jusitfy-content-center">
                                                                                <img src={visibleIcon} width={12} height={15} alt="" />
                                                                                <small>{child?.clicks}</small>
                                                                            </button>
                                                                        </div>
                                                                        <div className="d-flex align-items-center">
                                                                            <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(child?.id)}>
                                                                                <img src={duplicateIcon} data-id={child?.id} width="14px" alt="" />
                                                                            </button>
                                                                            <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, child)}>
                                                                                <img src={gearPageIcon} data-id={child?.id} onClick={(e) => editModal(e, child)} width="14px" alt="" />
                                                                            </button>
                                                                            <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(child?.id)}>
                                                                                <img src={trashIcon} width="14px" alt="" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else if (child?.type == "meeting") {
                                                            return (
                                                                <div data-blockId={child?.id} data-folder={child?.id} data-type="meeting" className={"nested-block editor-link folder" + (child?.is_enabled == 1 ? "" : " disabled-link")} >
                                                                    <div className="d-flex align-items-center editor-link-first-half">
                                                                        {draggableIcon()}
                                                                        <img src={MeetingBlockIcon} width={30} className="me-3" alt="" />
                                                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                                                            <h5 className="editor-link-headings mb-0">{child?.name}</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center editor-link-second-half">
                                                                        <div className="d-flex align-items-center">
                                                                            <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded">
                                                                                <input type="radio" id={"off" + child?.id} name={"enabled" + child?.id} onChange={handleStatus} checked={child?.is_enabled == 0 ? true : false} data-id={child?.id} value={false} />
                                                                                <label for={"off" + child?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img src={closedEyeIcon} alt="" srcset="" /></label>
                                                                                <input type="radio" id={"on" + child?.id} name={"enabled" + child?.id} onChange={handleStatus} checked={child?.is_enabled == 1 ? true : false} data-id={child?.id} value={true} />
                                                                                <label for={"on" + child?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                                            </div>
                                                                            <button className="btn btn-sm ignore-class link-action-button py-01 me-2 w-auto d-flex align-items-center jusitfy-content-center">
                                                                                <img src={visibleIcon} width={12} height={15} alt="" />
                                                                                <small>{child?.clicks}</small>
                                                                            </button>
                                                                        </div>
                                                                        <div className="d-flex align-items-center">
                                                                            <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(child?.id)}>
                                                                                <img src={duplicateIcon} data-id={child?.id} width="14px" alt="" />
                                                                            </button>
                                                                            <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={child?.id} onClick={(e) => editModal(e, child)}>
                                                                                <img src={gearPageIcon} data-id={child?.id} onClick={(e) => editModal(e, child)} width="14px" alt="" />
                                                                            </button>
                                                                            <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={child?.id} onClick={() => onDelete(child?.id)}>
                                                                                <img src={trashIcon} width="14px" alt="" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}

                                                    {/* </ReactSortable> */}
                                                </div>
                                            </div>
                                        )
                                    else if (val?.type == "link") {
                                        let destination_url = null;
                                        if (val?.details?.meta)
                                            destination_url = JSON.parse(val?.details?.meta)?.destination_url
                                        return (
                                            <div id="singleBlockDrag" className={"nested-block editor-link folder"} data-blockId={val?.id} data-type="link" key={index} data-folder={val?.id}>
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={linkIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                        <h6 className="text-muted-light mt-1 mb-0">{val?.destination_url ? (val?.destination_url.length > 21 ? val?.destination_url.slice(0, 21) + '...' : val?.destination_url) : null}</h6>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "><img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " ><img src={openedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button py-01 me-2 w-auto d-flex align-items-center jusitfy-content-center">
                                                            <img src={visibleIcon} width={12} height={15} alt="" />
                                                            <small className="ms-1">{val?.clicks}</small>
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val?.type)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (val?.type == "email_widget") {
                                        return (
                                            <div id="singleBlockDrag" className={"editor-link folder"} data-folder={val?.id}>
                                                <div className={`d-flex align-items-center editor-link-first-half email-card-${val?.id}`} >
                                                    {draggableIcon()}
                                                    <img src={emailIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "><img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " ><img src={openedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (val?.type == "tiktok") {
                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"}>
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={tiktokIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "><img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " ><img src={openedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (val?.type == "twitter") {
                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"} >
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={twitterIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "><img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " ><img src={openedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (val?.type == "youtube") {
                                        return (

                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"}>
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={youtubeIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "><img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " ><img src={openedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (val?.type == "text_block") {
                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"}>
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={textBlockIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="mb-0 editor-link-headings">{JSON?.parse(val?.details?.meta)?.text.length > 21 ? JSON?.parse(val?.details?.meta)?.text.slice(0, 21) + '...' : JSON?.parse(val?.details?.meta)?.text}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "><img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " ><img src={openedEyeIcon} width={15} height={15} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)} >
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (val?.type == "product") {
                                        return (
                                            <div id="singleBlockDrag" data-blockId={val?.id} data-type="product" data-folder={val?.id} className={"nested-block editor-link folder"}>
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={productBlockIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                        <button className="btn btn-sm ignore-class link-action-button py-01 me-2 w-auto d-flex align-items-center jusitfy-content-center">
                                                            <img src={visibleIcon} width={12} height={15} alt="" />
                                                            <small>{val?.clicks}</small>
                                                        </button>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (val?.type == "meeting") {
                                        return (
                                            <div id="singleBlockDrag" data-blockId={val?.id} data-type="meeting" data-folder={val?.id} className={"nested-block editor-link folder"} >
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={MeetingBlockIcon} width={30} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        {/* <label className="my-0 ms-0 me-2 link-switch">
                                                            <input type="checkbox" className="" checked={val?.is_enabled == 1 ? true : false} onChange={handleStatus} data-id={val?.id} />
                                                            <span className="slider round"></span>
                                                        </label> */}
                                                        {/* <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded">
                                                            <input type="radio" id={"off"+val?.id} name={"enabled"+val?.id}  onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false}/>
                                                            <label for={"off"+val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on"+val?.id} name={"enabled"+val?.id}  onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true}/>
                                                            <label for={"on"+val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                        </div> */}
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                        <button className="btn btn-sm ignore-class link-action-button py-01 me-2 w-auto d-flex align-items-center jusitfy-content-center">
                                                            <img src={visibleIcon} width={12} height={15} alt="" />
                                                            <small>{val?.clicks}</small>
                                                        </button>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (val?.type == "embed") {
                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"} >
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={embed_svg} style={{ width: "30px" }} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (val?.type == "contact") {

                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"}  key={index}>
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={contactIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    } else if (val?.type == "instagram") {
                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"} >
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={instagramIcon} width={30} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (val?.type == "zaap_posts") {
                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"} >
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={postIcon} width={30} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (val?.type == "lead_magnet") {
                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"} >
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={leadMagnetIcon} width={30} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div id="singleBlockDrag" data-folder={val?.id} className={"editor-link folder"}  key={index}>
                                                <div className="d-flex align-items-center editor-link-first-half">
                                                    {draggableIcon()}
                                                    <img src={twitterIcon} className="me-3" alt="" />
                                                    <div className="d-flex align-items-start justify-content-center flex-column">
                                                        <h5 className="editor-link-headings mb-0">{val?.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center editor-link-second-half">
                                                    <div className="d-flex align-items-center">
                                                        {/* <label className="my-0 ms-0 me-2 link-switch">
                                                            <input type="checkbox" className="" checked={val?.is_enabled == 1 ? true : false} onChange={handleStatus} data-id={val?.id} />
                                                            <span className="slider round"></span>
                                                        </label> */}
                                                        {/* <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded">
                                                            <input type="radio" id={"off"+val?.id} name={"enabled"+val?.id}  onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false}/>
                                                            <label for={"off"+val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on"+val?.id} name={"enabled"+val?.id}  onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true}/>
                                                            <label for={"on"+val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                        </div> */}
                                                        <div class="up-in-toggle bg-f1f1f1 ignore-class schedule-toggle btn-semi-rounded switcher-u">
                                                            <input type="radio" id={"off" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 0 ? true : false} data-id={val?.id} value={false} />
                                                            <label for={"off" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded "  > <img className={`${val?.is_enabled == 0 ? "redeye" : " "}`} src={closedEyeIcon} alt="" srcset="" /></label>
                                                            <input type="radio" id={"on" + val?.id} name={"enabled" + val?.id} onChange={handleStatus} checked={val?.is_enabled == 1 ? true : false} data-id={val?.id} value={true} />
                                                            <label for={"on" + val?.id} class="btn-semi-rounded newsletter-btn-semi-rounded " > <img src={openedEyeIcon} alt="" srcset="" /></label>
                                                            <span className={`toggle_switcher ${val?.is_enabled == 0 ? "swtichActive" : " "}`}></span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button className="btn btn-sm ignore-class link-action-button" onClick={() => duplicateBlock(val?.id)}>
                                                            <img src={duplicateIcon} data-id={val?.id} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                            <img src={gearPageIcon} data-id={val?.id} onClick={(e) => editModal(e, val)} width="14px" alt="" />
                                                        </button>
                                                        <button className="btn btn-sm ignore-class link-action-button ms-2" data-id={val?.id} onClick={() => onDelete(val?.id)}>
                                                            <img src={trashIcon} width="14px" alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>}
                        <div className="editor-header d-flex align-items-center justify-content-start ">
                            <button className="btn btn-add-block me-3 newBlockModal w-100"
                                // data-bs-toggle="modal" data-bs-target="#newBlockModal"
                                onClick={() => showModal("newBlockModal")}>
                                <img src={plusIcon} alt="" width={10} />
                                <span>Add block</span>
                            </button>
                        </div>
                    </section>

                    <div className="right-side-bar d-flex align-items-center flex-column justify-content-start">

                        <div className="page-preview-link d-flex align-items-center justify-content-between mb-4" style={{ background: "#F3F3F3px" }}>
                            <div>
                                {/* <b className="">My Page:</b> */}
                                <a className="text-decoration-none text-dark" href={`${protocol + pageUrl}/${slug}`} target="blank"> <span className="ms-2">{pageUrl}/{slug}</span> </a>
                            </div>
                            <img className="cursor-pointer" src={copyComponent} width="16px" alt="" onClick={() => copyingText(`${pageUrl}/${slug}`)} />
                        </div>
                        <div className="page-preview-mobile">
                            <iframe src={iframeURL} key={iframeIndex} frameBorder="0" ></iframe>
                            <div className="page-preview-loader">
                                <div className="spinner">
                                    <div className="bar1"></div>
                                    <div className="bar2"></div>
                                    <div className="bar3"></div>
                                    <div className="bar4"></div>
                                    <div className="bar5"></div>
                                    <div className="bar6"></div>
                                    <div className="bar7"></div>
                                    <div className="bar8"></div>
                                    <div className="bar9"></div>
                                    <div className="bar10"></div>
                                    <div className="bar11"></div>
                                    <div className="bar12"></div>
                                </div>
                            </div>
                        </div>
                        {
                        !pro &&
                        <div className="remove-logo-cont">
                            <span className="d-flex align-items-center gap-1">
                                <span className="gradient-eye-icon">
                                    <img src={gradientEye} alt="" />
                                    <img src={gradientEye} alt="" />
                                </span>
                                Hide Zaap Logo
                            </span>
                            <a href={`/project/${slug}/settings`} className="border-0 text-decoration-none header-link upgrade-main-btn">
                                Upgrade
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <path d="M1 7L7 1M7 1H3M7 1V5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                        </div>
                        }
                    </div>
                </div>
            </div>

            {confirmationModalShow?.show &&
                <Modal show={confirmationModalShow?.show} onHide={handleCloseConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{confirmationModalShow?.text}</Modal.Body>
                    <form onSubmit={confirmedDeleteBlock}>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseConfirmation}>
                                No
                            </Button>
                            <Button variant="primary" type="submit" autoFocus>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }

            {state?.congratsOpen && <CongratsModal show={true} />}
            {blockModal?.show && <BlockEditor show={blockModal?.show} slug={slug} handleSubmit={handleSubmit} handleClose={handleClose} blockModal={blockModal} setBlockModal={setBlockModal} from="folder_block" returnIcon={returnIcon} />}
            {linkModal?.show && <CreateLinkModal show={linkModal?.show} parent_id={linkModal?.parent_id} basePath={basePath} mainPath={mainPath} setshowConfirmation={setconfirmationLinkModal} history={history} handleHide={handleHide} returnIcon={returnIcon} handleSubmit={handleSubmit} error={error} from={"main"} />}
            <CustomizePage style={style} removeModal={removeModal} showModal={showModal} />

            {editLinkModal?.show ? <EditLinkModal show={true} returnIcon={returnIcon} editLinkModal={editLinkModal} handleSubmit={handleSubmit} error={error} /> : null}

            {emailWidgetModal?.show ? <CreateEmailWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setemailWidgetModal={setemailWidgetModal} emailWidgetModal={emailWidgetModal} /> : null}

            {textBlockModal?.show ? <CreateTextBlockModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} settextBlockModal={settextBlockModal} textBlockModal={textBlockModal} /> : null}

            {folderModal?.show ? <CreateFolderModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setfolderModal={setfolderModal} folderModal={folderModal} /> : null}

            {productModal?.show ? <CreateProductWidgetModal slug={slug} handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setproductModal={setproductModal} productModal={productModal} /> : null}
            {calendarProductModal?.show ? <CreateMeetingProductWidgetModal slug={slug} handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setcalendarProductModal={setcalendarProductModal} calendarProductModal={calendarProductModal} /> : null}
            {calendarProductModal?.show ? <CreateLeadMagnetWidgetModal slug={slug} handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setcalendarProductModal={setcalendarProductModal} calendarProductModal={calendarProductModal} /> : null}

            {youtubeModal?.show ? <CreateYoutubeWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setyoutubeModal={setyoutubeModal} youtubeModal={youtubeModal} /> : null}

            {tiktokModal?.show ? <CreateTiktokWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} settiktokModal={settiktokModal} tiktokModal={tiktokModal} /> : null}
            {instagramModal?.show ? <CreateInstagramWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setinstagramModal={setinstagramModal} instagramModal={instagramModal} /> : null}

            {twitterModal?.show ? <CreateTwitterWidgetModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} settwitterModal={settwitterModal} twitterModal={twitterModal} /> : null}
            {contactModal?.show ? <CreateContactModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setcontactModal={setcontactModal} contactModal={contactModal} /> : null}
            {zaapPostsModal?.show ? <CreateZaapPostsModal handleSubmit={handleSubmit} show={true} returnIcon={returnIcon} setzaapPostsModal={setzaapPostsModal} zaapPostsModal={zaapPostsModal} /> : null}

            {embedBlockModal?.show &&
                <CreateEmbedBlockModal
                    show={embedBlockModal?.show} embedBlockModal={embedBlockModal} setembedBlockModal={setembedBlockModal} handleHide={handleHide} returnIcon={returnIcon} handleSubmit={handleSubmit} error={error} />}


            <CroppieModal show={showCroppieModal} handleHide={handleHide} croppieClose={croppieClose} croppedImage={croppedImage} />
            {leadMagnetModal?.show &&
                <CreateLeadMagnetWidgetModal
                    show={leadMagnetModal?.show} leadMagnetModal={leadMagnetModal} setLeadMagnetModal={setLeadMagnetModal} handleHide={handleHide} returnIcon={returnIcon} handleSubmit={handleSubmit} error={error} />}

            <EditLinkProfilePicUploadModal showprofilePicModal={showEditProfilePicModal?.show} to={showEditProfilePicModal?.to} croppieClose={croppieClose} handleHide={handleHide} from="profile" />
            <SeoUploadModal showprofilePicModal={showSeoPictureModal?.show} to={showSeoPictureModal?.to} croppieClose={croppieClose} handleHide={handleHide} from="profile" />
            <ProfilePicUploadModal showprofilePicModal={showprofilePicModal?.show} to={showprofilePicModal?.to} croppieClose={croppieClose} handleHide={handleHide} from="profile" />

            {confirmationLinkModal?.show &&
                <ConfirmationLinkModal confirmationLinkModal={confirmationLinkModal} setShow={setconfirmationLinkModal} setIframeURL={setIframeURL} callGetProject={callGetProject} />}


            {bannerModal.show && <UploadImageBanner basePath={basePath} handleHide={handleHide} returnIcon={returnIcon} slug={slug} />}

            {/* {showCustomDomainDotsModal?.dotModal && <CustomDomainDotModal show={showCustomDomainDotsModal?.dotModal} />} */}
            {/* {showCustomDomainDotsModal?.connectedModal && <CustomDomainConnectedModal show={showCustomDomainDotsModal?.connectedModal} handleHide={handleHideConnectedModal} />} */}
        </>
    )
}
export default PageEditor;