import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../assets/img/plus.svg"
import dotsIcon from "../../assets/img/dots.svg";
import { ChevronRight } from "../../utils/svg";
import gearIcon from "../../assets/img/gear.svg";
import sparklesIcon from "../../assets/img/sparkles.svg"
import { apiUrl, pageUrl } from "../../utils/url";
import ConfirmationModal from "../confirmation-modal";
import DashboardProjects from "../dashboard/projects"
import UpgradeModal from "../upgrade/upgrade-modal";
import { audiencePosts as apiService } from "../../services/postRequests/audience";
import { hideLoader, showLoader } from "../loader";
import Avaterpic from "../../assets/img/products/avaterpicture.svg"
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import chevronDown from "../../assets/img/chevron-down.svg";

const SideBar = ({ setSelectedProject, isLoading, sharedProjects, projects, isPro, setStats, handleOpenModal, getProjects, setbasePath, setSelectedProfile, selectedProfile, selectedSlug, setShowModal }) => {

    const [showUpgradeModal, setshowUpgradeModal] = useState(false);
    const openUpgradeModal = () => setshowUpgradeModal(true)
    const closeUpgradeModal = () => setshowUpgradeModal(false)
    const [title, setTitle] = useState(false);
    const [profile, setProfile] = useState(false);
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null });
    const username = localStorage.user
    useEffect(() => {
        projects?.filter((val) => val?.id == selectedProfile).map((value, index) => (
            setProfile(value?.settings[1]?.value),
            setTitle(value?.settings[0]?.value)
        ))
    }, [selectedProfile]);
    const splitName = () => {
        let split_name = username.split(" ");
        const number_of_spaces = split_name.length > 1 ? [split_name[0], split_name[split_name.length - 2], split_name[split_name.length - 1]].toString().split(",").length : split_name.length;
        let first_char;
        let sec_char;
        let space;
        if (number_of_spaces) {
            if (number_of_spaces == 1) {
                first_char = username.slice(0, 1)
                sec_char = username.slice(1, 2)
            }
            else if (number_of_spaces == 2) {
                space = username.indexOf(" ");
                first_char = username.slice(0, 1)
                sec_char = username.slice(space + 1, space + 2)
            }
            else if (number_of_spaces == 3) {
                space = username.lastIndexOf(" ");
                first_char = username.slice(0, 1)
                sec_char = username.slice(space + number_of_spaces - 2, space + number_of_spaces - 1)
            }
            else {
                space = username.lastIndexOf(" ");
                first_char = username.slice(0, 1)
                sec_char = username.slice(space + number_of_spaces - 2, space + number_of_spaces - 1)
            }
            return (first_char + sec_char).toUpperCase()
        }
        else return "Loading..."
    };
    const getStats = (e, id, slug) => {

        e && e.preventDefault();
        setSelectedProfile(id)

        // console.log(slug)
        const api = apiUrl + "/project/get-dashboard/" + slug;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        apiService(api, hideLoader, setOptions)?.then((response) => {
            if (response) {
                setStats(response);
            }
        })
    }

    const deleteProject = (id) => {
        showLoader()
        const api = apiUrl + "/project/delete/" + id
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "DELETE",
            headers: myHeaders,
        }
        apiService(api, hideLoader, setOptions)?.then((response) => {
            if (response) {
                setconfirmationModalShow({ show: false, text: null })
                getProjects()
            }
            else {
                hideLoader()
            }
        }).catch(() => hideLoader())
    }
    const eventTrack = (event) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        formdata.append("name", "Click on pro button");

        const api = apiUrl + `/event-track`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        apiService(api, hideLoader, setOptions)?.then((response) => {
            if (response) {
                setconfirmationModalShow({ show: false, text: null })
                getProjects()
            }
            else {
                hideLoader()
            }
        }).catch(() => hideLoader())
        openUpgradeModal();
    }
    useEffect(() => {
        document.addEventListener('DOMContentLoaded', function() {
            var headerDropdown = document.querySelector('#project-dropdown');
            headerDropdown.onclick = () => headerDropdown.classList.toggle('active');
        });
    });
    return (
        <>
            <div className="left-side-bar d-flex align-items-center flex-column justify-content-between">
                <div className="w-100 h-100">
                    <h5>Projects</h5>
                    <div className="projects-main">
                        <DashboardProjects setShowModal={setShowModal} setStats={setStats} getProjects={getProjects} setSelectedProfile={setSelectedProfile} isLoading={isLoading} projects={projects} setbasePath={setbasePath} selectedProfile={selectedProfile} selectedSlug={selectedSlug} setconfirmationModalShow={setconfirmationModalShow} setSelectedProject={setSelectedProject} />
                    </div>
                    <button className="btn btn-primary btn-block d-flex align-items-center justify-content-center pt-3 pb-3 mt-2"
                        onClick={handleOpenModal}>
                        <img src={plusIcon} alt="" />
                        <b className="ms-2">New Project</b>
                    </button>
                    {sharedProjects?.length > 0 ? (
                        <h5 style={{ marginTop: "10px", paddingLeft: "5px" }}>Shared Projects</h5>
                    ) : (<></>)}
                    {isLoading ?
                        <div className="projects-main">
                            <div className="project-small d-flex align-items-center justify-content-between mb-2" id="project-small-dropdown">
                                <div className="d-flex align-items-center justify-content-start project-small-details-main">
                                    <div className="project-small-user-pp">
                                        <Skeleton
                                            circle
                                            height={45}
                                            width={45}
                                            containerClassName="avatar-skeleton"
                                        />
                                    </div>
                                    <div className="project-small-details d-flex align-items-start justify-content-center flex-column ps-2">
                                        <div className="project-small-project-title">
                                            <Skeleton />
                                        </div>
                                        <div className="project-small-project-link">
                                            <Skeleton />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : <div className="projects-main">
                            {sharedProjects?.length >= 1 && <div className="name-lis-scroll" >
                                {sharedProjects.map((val, index) => {

// console.log(val)
                                    return (
                                        <div key={index} className={`project-small d-flex align-items-center justify-content-between mb-2 ${val?.id == selectedProfile ? "active" : null}`} id="project-small-dropdown"
                                        >
                                            <div className="d-flex align-items-center justify-content-start project-small-details-main"
                                                onClick={(e) => {
                                                    localStorage.slug = val?.project?.slug
                                                    getStats(e, val?.id, val?.project?.slug)
                                                    setSelectedProject(false);
                                                }}>
                                                <div className="project-small-user-pp">
                                                    <img src={val?.project?.settings[1]?.value} width="100%" alt="" />
                                                </div>
                                                <div className="project-small-details d-flex align-items-start justify-content-center flex-column ps-2">
                                                    <div className="project-small-project-title">
                                                        <b>{val?.project?.settings[0]?.value}</b>
                                                    </div>
                                                    <div className="project-small-project-link"
                                                    >
                                                        <span className="text-muted-light">{pageUrl}/{val?.project?.slug}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="project-small-action-button dropdown-toggle noselect d-md-flex d-none" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={dotsIcon} alt="" style={{padding: "10px 5px"}} />
                                            </div>
                                            <ul className="dropdown-menu" aria-labelledby="project-small-dropdown">
                                                <li>
                                                    <Link to={`project/${val?.project?.slug}/page-editor`} className="dropdown-item">
                                                        <span className="me-5">
                                                            Edit Page
                                                        </span>
                                                        <ChevronRight />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`project/${val?.project?.slug}/analytics`} className="dropdown-item">
                                                        <span className="me-5">
                                                            Page Analytics
                                                        </span>
                                                        <ChevronRight />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`project/${val?.project?.slug}/products`} className="dropdown-item">
                                                        <span className="me-5">
                                                            Products
                                                        </span>
                                                        <ChevronRight />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`project/${val?.project?.slug}/audience`} className="dropdown-item">
                                                        <span className="me-5">
                                                            Community
                                                        </span>
                                                        <ChevronRight />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`project/${val?.project?.slug}/settings/team-members`} className="dropdown-item">
                                                        <span className="me-5">
                                                            Page Settings
                                                        </span>
                                                        <ChevronRight />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`project/${val?.project?.slug}/duplicate`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setShowModal({ show: true, duplicate: true, slug: val?.project?.slug })
                                                    }} className="dropdown-item">
                                                        <span className="me-5">
                                                            Duplicate
                                                        </span>
                                                        <ChevronRight />
                                                    </Link>
                                                </li>
                                            </ul>
                                            <Link to={`project/${val?.project?.slug}/page-editor`} className="project-small-manage-button d-md-none d-flex align-items-center justify-content-center text-decoration-none" style={{ color: "#000" }}>
                                                <img src={gearIcon} width="17px" alt="" />
                                                <span><b>Manage</b></span>
                                            </Link>
                                        </div >
                                    )
                                })}
                            </div>}
                        </div>
                    }
                </div>
                <div className="project-dropdown-main d-flex align-items-center flex-column w-100">
                    {/* {!isPro && (
                        <div className="upgrade-div">
                            <img src={sparklesIcon} width="30px" alt="" />
                            <h3 className="my-3">Upgrade to level<br />up your page!</h3>
                            <button className="btn upgrade-main-btn" onClick={eventTrack}>Upgrade</button>
                        </div>)
                    } */}
                        <div className="account-div project-dropdown d-flex align-items-center ps-1 dropdown-toggle noselect" id="project-dropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                            <div><img src={profile} alt="" width="45" /></div>
                            <div className="d-flex flex-column ps-2">
                                <div className="text-grey"><b>{title}</b></div>
                                <div style={{color:"#8C8E9C"}}>
                                    <span>
                                        zaap.bio/{localStorage?.slug}
                                    </span>
                                    <img src={chevronDown} id="project-chevron-down" width="15px" alt="" className="ms-2" /></div>
                                </div>
                        </div>
                        <ul className="project-dropdown-menu dropdown-menu " aria-labelledby="project-dropdown">
                            {projects?.map((value, index) => (
                                <li>
                                <div className="project-dropdown-item share-dropdown-item d-flex align-items-center ps-1" 
                                    style={localStorage.slug === value.slug ? { color: "#4397F7" ,background:"#F3F3F3",borderRadius:"10px"} : null}
                                    onClick={(e) => { localStorage.slug = value.slug
                                    getStats(e, value.id, value?.slug) }}>
                                    <div className="d-flex justify-content-start"><img src={value?.settings[1]?.value} alt="" width="25" /></div>
                                    <div className="d-flex ps-2 w-100">
                                        @{value?.slug}
                                    </div>
                                    {localStorage.slug == value.slug &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                            <rect x="1.0918" y="8.70801" width="6.53375" height="1.99973" rx="0.999867" transform="rotate(33.1196 1.0918 8.70801)" fill="#1188FF"/>
                                            <rect x="12.2461" y="3.57031" width="12.3984" height="1.99973" rx="0.999867" transform="rotate(123.12 12.2461 3.57031)" fill="#1188FF"/>
                                        </svg>
                                    }
                                </div>
                                </li>
                            ))}
                            {sharedProjects?.length >= 1 &&
                            sharedProjects.map((value, index) => (
                                <>
                                <li>
                                <b>Shared Projects</b>
                                </li>
                                <li>
                                <div className="project-dropdown-item share-dropdown-item d-flex align-items-center ps-1" 
                                    style={localStorage.slug === value.slug ? { color: "#4397F7" ,background:"#F3F3F3",borderRadius:"10px"} : null}
                                    onClick={(e) => {
                                        localStorage.slug = value?.project?.slug
                                        getStats(e, value?.id, value?.project?.slug)
                                        setSelectedProject(false);
                                    }}>
                                    <div className="d-flex justify-content-start"><img src={value?.project?.settings[1]?.value} alt="" width="25" /></div>
                                    <div className="d-flex ps-2 w-100">
                                        @{value?.project?.slug}
                                    </div>
                                    {localStorage.slug == value.slug &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                            <rect x="1.0918" y="8.70801" width="6.53375" height="1.99973" rx="0.999867" transform="rotate(33.1196 1.0918 8.70801)" fill="#1188FF"/>
                                            <rect x="12.2461" y="3.57031" width="12.3984" height="1.99973" rx="0.999867" transform="rotate(123.12 12.2461 3.57031)" fill="#1188FF"/>
                                        </svg>
                                    }
                                </div>
                                </li>
                            </>
                            ))
                            }
                            <li>
                                <div className="project-dropdown-item dropdown-item d-flex align-items-center ps-1 ">
                                    <div className="ms-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <path d="M6.64084 1.64085C6.64084 1.14885 6.242 0.75 5.75 0.75C5.258 0.75 4.85915 1.14884 4.85915 1.64085V11.3592C4.85915 11.8512 5.258 12.25 5.75 12.25C6.242 12.25 6.64084 11.8512 6.64084 11.3592V1.64085Z" fill="#333333"/>
                                            <path d="M0.890844 5.60916C0.398845 5.60916 0 6.008 0 6.5C0 6.992 0.398845 7.39085 0.890844 7.39085L10.6092 7.39085C11.1012 7.39085 11.5 6.992 11.5 6.5C11.5 6.008 11.1012 5.60915 10.6092 5.60915L0.890844 5.60916Z" fill="#333333"/>
                                        </svg>
                                    </div>
                                    <div className="d-flex flex-column ps-2 ms-2" onClick={handleOpenModal}>Add new Project</div>
                                </div>
                            </li>
                        </ul>
                </div>
            </div >
            {showUpgradeModal && <UpgradeModal isOpen={showUpgradeModal} onHide={closeUpgradeModal} />
            }
            {confirmationModalShow?.show && <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={deleteProject} />}
        </>
    )
}
export default SideBar